import { Routes, Route } from "react-router-dom";
import { routes, protectedRoutes, profileRoutes } from "./routes";
import { useSelector } from "react-redux";
import ProtectedRoute from "./Views/ProtectedRoute";
// import { MapboxMap } from "react-map-gl";
import "animate.css/animate.compat.css";
import "animate.css";
import Dashboard from "./Views/Dashboard/Dashboard";
import NotFound from "./Views/NotFound/NotFound";

function App() {
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  return (
    <Routes>
      {routes.map((page, index) => (
        <Route {...page} key={index} />
      ))}
      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        {protectedRoutes.map((page, index) => (
          <Route {...page} key={index} />
        ))}
        <Route path="/dashboard" element={<Dashboard />}>
          {profileRoutes.map((page, index) => (
            <Route {...page} key={index} />
          ))}
        </Route>
      </Route>
      <Route path="*" Component={NotFound} />
    </Routes>
  );
}
export default App;
