import React from "react";
import { Tooltip } from 'react-tooltip'
import "./stepsToFollow.css"

//Assets
import CheckCircle from "../../assets/icons/check_circle.svg";
const StepsToFollow = () => {

    return (
        <div className="container-card-steps">
            <div className="card-steps text-info p-2">
                <span>1 of 4 steps completed</span>
                <div className="d-flex justify-content-center pt-1">
                    <div className="step">
                        <span>Conoce</span>
                        <div className="rounded-circle"
                            id="MoreInfo"
                        >
                            <img src={CheckCircle} alt="circleCheck" />
                        </div>
                        <Tooltip anchorSelect="#MoreInfo" clickable className="tooltip">
                            <div className="d-flex flex-column align-items-center">
                                <span className="text-info m-2">Conoce mas sobre Radaria</span>
                                <button className="btn btn-primary">Más...</button>
                            </div>
                        </Tooltip>
                    </div>
                    <span className="fs-1 ps-1 pe-1 text-secondary">...</span>
                    <div className="step">
                        <span>Regístrate</span>
                        <div className="rounded-circle" id="Register">
                            <img src={CheckCircle} alt="circleCheck" />
                        </div>
                        <Tooltip anchorSelect="#Register" clickable className="tooltip">
                            <div className="d-flex flex-column align-items-center">
                                <span className="text-info m-2">Listo, listo... ¡Vamos!</span>
                                <button className="btn btn-primary d-flex">¡Vamos!</button>
                            </div>
                        </Tooltip>
                    </div>
                    <span className="fs-1 ps-1 pe-1 text-secondary">...</span>

                    <div className="step">
                        <span>Entrena</span>
                        <div className="rounded-circle" id="Coaches">
                            <img src={CheckCircle} alt="circleCheck" />
                        </div>
                        <Tooltip anchorSelect="#Coaches" clickable className="tooltip">
                            <div className="d-flex flex-column align-items-center">
                                <span className="text-info m-2">Entrena con los mejores</span>
                                <button className="btn btn-primary">¡Vamos!</button>
                            </div>
                        </Tooltip>
                    </div>
                    <span className="fs-1 ps-1 pe-1 text-secondary">...</span>
                    <div className="step">
                        <span>Triunfa</span>
                        <div className="rounded-circle" id="Triumph">
                            <img src={CheckCircle} alt="circleCheck" />
                        </div>
                        <Tooltip anchorSelect="#Triumph" clickable className="tooltip">
                            <div className="d-flex flex-column align-items-center">
                                <span className="text-info m-2">Acepta el desafío y conéctate con los mejores.</span>
                                <button className="btn btn-primary ">¡Vamos!</button>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepsToFollow;