import React from "react";
import "./verifyCodeSMS.css"
import { useParams } from "react-router-dom";


//Assets
import ImgRunner from "../../assets/images/runningImg.webp";
import LogoRadaria from "../../assets/images/logotipos/RADARIA-LOGOTIPO01.png";
import FormCode from "./FormCode/FormCode.jsx";
const VerifyCodeSMS = () => {
    const { token } = useParams();
    return (
        <div className="container-fluid d-flex bg-black">
            <section className="container-narrow ">
                <h2 className="text-info fs-2">
                    Encouraging an environment that inspires and motivates runners to achieve their
                    goals and exceed their limits.
                </h2>
            </section>
            <section className="conatiner-narrow-code">
                <a href="/">
                    <img src={LogoRadaria} alt="LogoRadaria" width={300} />
                </a>
                <FormCode token={token} />
                <img src={ImgRunner} width={300} alt="Running Person" />
            </section>

        </div>
    );
};

export default VerifyCodeSMS;