import React, { useState } from "react";
import Chart from "react-apexcharts";

const TimeGraphics = () => {
  // Gráfico de barras
  const [barOptions, setBarOptions] = useState({
    chart: {
      id: "weekly-stats",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ],
      labels: {
        style: {
          colors: [
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
          ],
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => `${value} min`,
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    title: {
      text: "Tiempo recorrido semanal (minutos)",
      align: "center",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#FFFFFF",
      },
    },
    colors: ["#A1C6BB"],
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#00000"],
        fontSize: "12px",
        fontWeight: "bold",
      },
    },
    fill: {
      type: "solid",
    },
  });

  const [barSeries, setBarSeries] = useState([
    {
      name: "Tiempo recorrido",
      data: [30, 45, 40, 50, 60, 90, 70], // Ejemplo de datos en minutos
    },
  ]);

  return (
    <div>
      {/* Gráfico de barras */}
      <Chart options={barOptions} series={barSeries} type="bar" height={350} />
    </div>
  );
};

export default TimeGraphics;
