import React from "react";
import "./validateSuccessEmail.css";
import { Link, useParams } from "react-router-dom";

const ValidateSuccessEmail = () => {
  //Recibe nombre de usuario y origen para mostrar pantalla dependiendo del tipo de registro
  const { name, origin } = useParams();

  return (
    <div className="container_verified ">
      <section className="subwraper">
        <h1 className="text-center text-white fs-4">
          ¡Bienvenido a Radaria! {name}. 🎉
        </h1>
        <div className="d-flex flex-column align-items-center gap-1">
          <span className="text-center text-white">
            Se verifico con exito tu correo{" "}
          </span>
          <span className="text-center text-white">
            ¡Prepárate para correr juntos hacia tus metas! 🏃‍♂️🌟
          </span>
        </div>
        {origin === "web" ? (
          <Link to="/auth" className="btn btn-primary">
            Iniciar sesión
          </Link>
        ) : (
          <span className="text-primary">Inicia sesión en la app</span>
        )}
      </section>
    </div>
  );
};

export default ValidateSuccessEmail;
