import React from "react";
import "./validateInfo.css";
//Components
// import Navbar from '../../components/navbar/navbar';
// import FormValidateInfo from './FormVlidateInfo/FormValidate';
import { Link, useParams } from "react-router-dom";
//Assets

const ValidateInfo = () => {
  const { token, name } = useParams();
  console.log(token);
  const origin = "web";
  return (
    <div className="container_verified">
      {/* <Navbar /> */}
      <div className="container d-flex justify-content-center align-items-center">
        <div className="container-form-info mb-5  p-3">
          <div className="welcome-info">
            Tu Email se verifico Correctamente <span>Radaria</span>
            <span>{name}</span>!
          </div>
          {origin === "app" ? (
            "Inicia sesion en la app"
          ) : (
            <Link to="/auth">Inicia sesion en la web</Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidateInfo;
