import React, { useState } from "react";
import Chart from "react-apexcharts";

const RunnerStats = () => {
  // Gráfico de pastel
  const [pieOptions, setPieOptions] = useState({
    chart: {
      type: "pie",
    },
    labels: ["0-1 km", "1-2 km", "2-3 km", "3-4 km", "4+ km"],
    title: {
      text: "Distribución de tiempo por km",
      align: "center",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#FFFFFF",
      },
    },
    colors: ["#A1C6BB", "#FFC300", "#DAF7A6", "#1E88E5", "#C70039"],
    legend: {
      position: "right",
      labels: {
        colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"], // Colores de las etiquetas
        useSeriesColors: false, // Evita usar colores automáticos
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    dataLabels: {
      style: {
        colors: ["#FFFFFF"],
      },
    },
    tooltip: {
      theme: "dark",
    },
  });

  const [pieSeries, setPieSeries] = useState([15, 25, 30, 20, 10]); // Ejemplo de datos en minutos
  return (
    <div>
      {/* Gráfico de pastel */}
      <Chart options={pieOptions} series={pieSeries} type="pie" height={350} />
    </div>
  );
};

export default RunnerStats;
