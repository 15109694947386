import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated }) => {
  console.log(isAuthenticated);

  // Si el usuario no está autenticado, redirige a la página de login
  if (!isAuthenticated) {
    return <Navigate to="/auth" replace />;
  }

  // Si el usuario está autenticado, permite que acceda a las rutas hijas
  return <Outlet />;
};

export default ProtectedRoute;
