import React, { useState } from "react";
import "./formSignUp.css";
import validateForm from "./Validate";
import { registerUser } from "../../../services/users/userService";
import toast from "react-hot-toast";
import Loading from "../../../components/Loading/Loading";
import { FaEye } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { login, setUser } from "../../../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";

const FormSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialFormValues = {
    firstName: "",
    nickName: "",
    email: "",
    password: "",
    origin: "web",
  };
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setAcceptTerms(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formValues);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const userData = { ...formValues };
      userData.origin = "web";
      delete userData[""];

      if (acceptTerms) {
        setLoading(!loading);
        const response = await registerUser(userData);
        if (!response.status) {
          setLoading(false);
          toast.error(response.message, { duration: 10000 });
          setAcceptTerms(false);
        } else {
          const message = response.data.message;
          setLoading(false);
          dispatch(
            login({ token: response.data?.token, isAuthenticated: true })
          );
          dispatch(setUser(response.data?.user));
          navigate("/dashboard/history");
          toast.success(`${message}`, { duration: 10000 });
          setAcceptTerms(false);
          setFormValues(initialFormValues);
          setErrors({});
        }
      }
      setErrors({});
    }
  };

  return (
    <>
      <Loading visible={loading} />
      <div className="d-flex flex-column align-items-center">
        <form onSubmit={handleSubmit} className="form-sign-up needs-validation">
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">
              Nombre
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.firstName && "is-invalid input-error"
              } text-light`}
              id="firstName"
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
              placeholder="Nombre"
            />
            {errors.firstName && (
              <div id="validationServer05Feedback" className="invalid-feedback">
                {errors.firstName}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Correo Electrónico
            </label>
            <input
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className={`form-control ${
                errors.email && "is-invalid input-error"
              } text-light`}
              id="inputEmai"
              placeholder="correo@ejemplo.com"
            />
            {errors.email && (
              <div id="validationServer05Feedback" className="invalid-feedback">
                {errors.email}
              </div>
            )}
          </div>
          <div className="">
            <label htmlFor="inputUserName" className="form-label">
              Usuario
            </label>
            <input
              type="text"
              name="nickName"
              value={formValues.nickName}
              onChange={handleChange}
              className={`form-control ${
                errors.nickName && "is-invalid input-error"
              } text-light`}
              id="inputUserName"
              placeholder="Usuario"
            />
            {errors.nickName && (
              <div id="validationServer05Feedback" className="invalid-feedback">
                {errors.nickName}
              </div>
            )}
          </div>
          <div className="">
            <label htmlFor="inputPasswordLogin" className="form-label mb-1">
              Contraseña
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formValues.password}
                onChange={handleChange}
                className={`form-control ${
                  errors.password && "is-invalid input-error"
                } text-light`}
                id="inputPassword"
                placeholder="Contraseña"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon2"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FaEye />
              </button>
            </div>
            {errors.password && (
              <div id="validationServer05Feedback" className="invalid-feedback">
                {errors.password}
              </div>
            )}
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center justify-content-center m-2 form-check">
              <input
                type="checkbox"
                checked={acceptTerms}
                className={`form-check-input mt-0 ${
                  !acceptTerms && "is-invalid input-error border border-danger"
                }`}
                onChange={handleChange}
                id="checkTerms"
              />
              <label htmlFor="checkTerms" className="form-check-label ms-2">
                Accept terms
              </label>
            </div>
            <button type="submit" className="btn btn-primary">
              CREATE
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormSignUp;
