import React, { useEffect, useRef } from "react";
import Chart from "react-apexcharts";

const PieGraphic = ({ labels = [], pieSeries = [], title = "" }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.updateOptions({
        labels: labels,
        series: pieSeries,
      });
    }
  }, [labels, pieSeries]);
  // Gráfico de pastel
  const pieOptions = {
    chart: {
      type: "pie",
    },
    labels: labels,
    title: {
      text: title,
      align: "center",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#FFFFFF",
      },
    },
    colors: ["#A1C6BB", "#FFC300", "#DAF7A6", "#1E88E5", "#C70039"],
    legend: {
      position: "right",
      labels: {
        colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"], // Colores de las etiquetas
        useSeriesColors: false, // Evita usar colores automáticos
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    dataLabels: {
      style: {
        colors: ["#FFFFFF"],
      },
    },
    tooltip: {
      theme: "dark",
    },
  };
  return (
    <div key={JSON.stringify(pieSeries)}>
      {/* Gráfico de pastel */}
      <Chart
        ref={chartRef}
        options={pieOptions}
        series={pieSeries}
        type="pie"
        height={350}
      />
    </div>
  );
};

export default PieGraphic;
