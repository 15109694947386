import handleErrorAPI from "../../utils/handleErrorAPI";
import apiClient from "../apiClient";
import { store } from "../../redux/store";

export const getRoutes = async () => {
  try {
    const userData = store.getState().authReducer;
    console.log(userData);

    const routesData = await apiClient.get(
      `rutasMapa/getUserById/${userData.id}`
    );
    return {
      status: true,
      routes: routesData.data,
    };
  } catch (error) {
    return handleErrorAPI(error);
  }
};
