// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap-testimonial {
  width: 100%;
  padding: 20px;
  overflow: hidden;
  font-family: 'CodecProRegular';
}
.subWrap-cards {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.wrap-testimonial h3 {
  font-size: 30px;
  font-family: 'HankenGrotesk';
  font-weight: bold;
  color: var(--bs-secondary);
}
.wrap-testimonial p {
  font-size: 16px;
  text-align: center;
}
.card-container {
  display: flex;
  gap: 30px;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .wrap-testimonial h3 {
    font-size: 35px;
  }
  .wrap-testimonial p {
    font-size: 18px;
    width: 80%;
    text-align: center;
  }
}
@media only screen and (min-width: 1024px) {
  .wrap-testimonial h3 {
    font-size: 40px;
  }
  .wrap-testimonial p {
    font-size: 20px;
    width: 70%;
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Home/styles/testimonialSection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,4BAA4B;EAC5B,iBAAiB;EACjB,0BAA0B;AAC5B;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,UAAU;IACV,kBAAkB;EACpB;AACF;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,UAAU;IACV,kBAAkB;EACpB;AACF","sourcesContent":[".wrap-testimonial {\n  width: 100%;\n  padding: 20px;\n  overflow: hidden;\n  font-family: 'CodecProRegular';\n}\n.subWrap-cards {\n  display: flex;\n  flex-direction: column;\n  padding: 30px;\n  gap: 20px;\n  justify-content: center;\n  align-items: center;\n}\n.wrap-testimonial h3 {\n  font-size: 30px;\n  font-family: 'HankenGrotesk';\n  font-weight: bold;\n  color: var(--bs-secondary);\n}\n.wrap-testimonial p {\n  font-size: 16px;\n  text-align: center;\n}\n.card-container {\n  display: flex;\n  gap: 30px;\n  justify-content: center;\n}\n\n@media only screen and (min-width: 768px) {\n  .wrap-testimonial h3 {\n    font-size: 35px;\n  }\n  .wrap-testimonial p {\n    font-size: 18px;\n    width: 80%;\n    text-align: center;\n  }\n}\n@media only screen and (min-width: 1024px) {\n  .wrap-testimonial h3 {\n    font-size: 40px;\n  }\n  .wrap-testimonial p {\n    font-size: 20px;\n    width: 70%;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
