import { createSlice } from "@reduxjs/toolkit";

//initalState
const initialState = {
  id: "",
  email: "",
  isAdmin: false,
  membership: "",
  membershipStatus: "",
  status: "",
  token: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setUser: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.isAdmin = action.payload.isAdmin;
      state.membership = action.payload.membership;
      state.membershipStatus = action.payload.membershipStatus;
      state.status = action.payload.status;
    },
    setStatusAccount: (state, action) => {
      state.isAdmin = action.payload.isAdmin;
      state.membership = action.payload.membership;
      state.membershipStatus = action.payload.membershipStatus;
      state.status = action.payload.status;
    },
    logOut: (state) => {
      state.id = "";
      state.email = "";
      state.isAdmin = false;
      state.membership = "";
      state.membershipStatus = "";
      state.status = "";
      state.token = null;
      state.isAuthenticated = false;
    },
  },
});

export const { login, setUser, setStatusAccount, logOut } = authSlice.actions;
export default authSlice.reducer;
