import React, { useEffect, useState } from "react";
import "./styles/sectionListCoaches.css";
import ImgCoach from "../../assets/images/temporalImages/coach.png";

const SectionListCoaches = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const coachesList = [
    {
      name: "Melissa Alvarez",
      description: "Running profesional con especialidad en nutricion",
      image: ImgCoach,
    },
    {
      name: "Melissa Alvarez1",
      description: "Running profesional con especialidad en nutricion",
      image: ImgCoach,
    },
    {
      name: "Melissa Alvarez2",
      description: "Running profesional con especialidad en nutricion",
      image: ImgCoach,
    },
    {
      name: "Melissa Alvarez3",
      description: "Running profesional con especialidad en nutricion",
      image: ImgCoach,
    },
    {
      name: "Melissa Alvarez4",
      description: "Running profesional con especialidad en nutricion",
      image: ImgCoach,
    },
    {
      name: "Melissa Alvarez4",
      description: "Running profesional con especialidad en nutricion",
      image: ImgCoach,
    },
  ];
  useEffect(() => {
    const updateItemsPerPage = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setItemsPerPage(3);
      } else if (width >= 768) {
        setItemsPerPage(2);
      } else {
        setItemsPerPage(1);
      }
    };

    updateItemsPerPage();

    window.addEventListener("resize", updateItemsPerPage);

    // Eliminar el listener cuando se desmonte el componente
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = coachesList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(coachesList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <section className="full-container-list-coaches pt-5">
      <section className="container-cards-coaches">
        <h2 className="text-white fw-bold fs-1">Nuestos Coaches</h2>
        <article className="wrap-cards">
          {currentItems.map((coach, index) => {
            return (
              <div key={index} className="card-coach">
                <div>
                  <img src={coach.image} alt="coachImage" />
                </div>
                <div className="blur-button">
                  <button className="btn btn-primary">Ver perfil</button>
                </div>
                <h4 className="text-secondary text-center">{coach.name}</h4>
                <p className="text-white text-center">{coach.description}</p>
              </div>
            );
          })}
        </article>
        <div className="pagination">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-primary"
          >
            &lt;
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={`${number === currentPage ? "active" : ""} btn-circle`}
            ></button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
            className="btn btn-primary"
          >
            &gt;
          </button>
        </div>
      </section>
    </section>
  );
};

export default SectionListCoaches;
