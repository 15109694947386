// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
  width: 100%;
  height: 42px;
  background-color: var(--bs-transparentColor);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px; /* Bordes redondeados */
  padding: 0 10px;
}
.search-btn {
  font-size: 20px;
}
#searchBar {
  height: 40px;
  padding-left: 10px !important;
  background-color: transparent !important;
  border-bottom: none !important;
}
.search-bar .form-control {
  background-color: transparent; /* Fondo transparente ya que el contenedor tiene el fondo gris */
  color: white !important; /* Texto en blanco */
  border: none; /* Sin borde */
  outline: none; /* Elimina el borde en focus */
  box-shadow: none; /* Elimina cualquier sombra */
  flex-grow: 1; /* Para que ocupe todo el espacio disponible */
  font-size: 16px; /* Tamaño de fuente */
}

.search-bar .form-control::placeholder {
  color: white; /* Placeholder en blanco */
}

.search-bar .form-control:focus {
  outline: none;
  box-shadow: none; /* Sin sombra al hacer focus */
}

.search-bar::before {
  font-size: 20px; /* Tamaño del ícono */
  color: white; /* Ícono en blanco */
  margin-right: 10px; /* Espacio entre el ícono y el input */
}
`, "",{"version":3,"sources":["webpack://./src/components/SideBar/styles/searchbar.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,4CAA4C;EAC5C,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB,EAAE,uBAAuB;EAC3C,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,YAAY;EACZ,6BAA6B;EAC7B,wCAAwC;EACxC,8BAA8B;AAChC;AACA;EACE,6BAA6B,EAAE,gEAAgE;EAC/F,uBAAuB,EAAE,oBAAoB;EAC7C,YAAY,EAAE,cAAc;EAC5B,aAAa,EAAE,8BAA8B;EAC7C,gBAAgB,EAAE,6BAA6B;EAC/C,YAAY,EAAE,8CAA8C;EAC5D,eAAe,EAAE,qBAAqB;AACxC;;AAEA;EACE,YAAY,EAAE,0BAA0B;AAC1C;;AAEA;EACE,aAAa;EACb,gBAAgB,EAAE,8BAA8B;AAClD;;AAEA;EACE,eAAe,EAAE,qBAAqB;EACtC,YAAY,EAAE,oBAAoB;EAClC,kBAAkB,EAAE,sCAAsC;AAC5D","sourcesContent":["@import \"../../../assets/styles/global.css\";\n\n.search-bar {\n  width: 100%;\n  height: 42px;\n  background-color: var(--bs-transparentColor);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px; /* Bordes redondeados */\n  padding: 0 10px;\n}\n.search-btn {\n  font-size: 20px;\n}\n#searchBar {\n  height: 40px;\n  padding-left: 10px !important;\n  background-color: transparent !important;\n  border-bottom: none !important;\n}\n.search-bar .form-control {\n  background-color: transparent; /* Fondo transparente ya que el contenedor tiene el fondo gris */\n  color: white !important; /* Texto en blanco */\n  border: none; /* Sin borde */\n  outline: none; /* Elimina el borde en focus */\n  box-shadow: none; /* Elimina cualquier sombra */\n  flex-grow: 1; /* Para que ocupe todo el espacio disponible */\n  font-size: 16px; /* Tamaño de fuente */\n}\n\n.search-bar .form-control::placeholder {\n  color: white; /* Placeholder en blanco */\n}\n\n.search-bar .form-control:focus {\n  outline: none;\n  box-shadow: none; /* Sin sombra al hacer focus */\n}\n\n.search-bar::before {\n  font-size: 20px; /* Tamaño del ícono */\n  color: white; /* Ícono en blanco */\n  margin-right: 10px; /* Espacio entre el ícono y el input */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
