import React, { useState } from "react";
import "./styles/menu.css";
import { BsGraphUpArrow } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import { MdOutlineCardMembership } from "react-icons/md";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { logOut } from "../../redux/slice/authSlice";
import { useDispatch } from "react-redux";

const Menu = () => {
  const [openMenu, setOpenMenu] = useState(""); // Estado para rastrear el menú abierto
  const location = useLocation();
  const dispatch = useDispatch();

  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? "" : menu);
  };

  return (
    <div className="mt-3 d-flex flex-column gap-3">
      {/* Menú Dashboard */}
      <div>
        <p
          className={`menu-item ${
            openMenu === "menuDashboard" ? "menu-item-select" : ""
          }`}
          onClick={() => toggleMenu("menuDashboard")}
        >
          <span className="d-flex gap-2">
            <BsGraphUpArrow />
            Mi Progreso
          </span>
          {openMenu === "menuDashboard" ? (
            <IoIosArrowDown />
          ) : (
            <IoIosArrowForward />
          )}
        </p>
        <div
          className={`collapse ${openMenu === "menuDashboard" ? "show" : ""}`}
          id="menuDashboard"
        >
          <ul className="list-menu">
            <li
              className={`item ${
                location.pathname === "/dashboard/history" && "item-select"
              }`}
            >
              <Link to={"/dashboard/history"}>Historial de Actividad</Link>
            </li>
            <li
              className={`item ${
                location.pathname === "/dashboard/rutasComplete" &&
                "item-select"
              }`}
            >
              <Link to={"/dashboard/rutasComplete"}>Rutas Completadas</Link>
            </li>
            <li className="item">Mis Logros</li>
          </ul>
        </div>
      </div>

      {/* Menú Comunidades */}
      <div>
        <p
          className={`menu-item ${
            openMenu === "menuCommunity" ? "menu-item-select" : ""
          }`}
          onClick={() => toggleMenu("menuCommunity")}
        >
          <span className="d-flex gap-2">
            <FaUsers />
            Comunidades
          </span>
          {openMenu === "menuCommunity" ? (
            <IoIosArrowDown />
          ) : (
            <IoIosArrowForward />
          )}
        </p>
        <div
          className={`collapse ${openMenu === "menuCommunity" ? "show" : ""}`}
          id="menuCommunity"
        >
          <ul className="list-menu">
            <li className="item">Opción 1</li>
            <li className="item">Opción 2</li>
          </ul>
        </div>
      </div>

      {/*Menú de Rutas */}
      <div>
        <p
          className={`menu-item ${
            openMenu === "menuRoutes" ? "menu-item-select" : ""
          }`}
          onClick={() => toggleMenu("menuRoutes")}
        >
          <span className="d-flex gap-2">
            <FaMapLocationDot />
            Rutas
          </span>
          {openMenu === "menuRoutes" ? (
            <IoIosArrowDown />
          ) : (
            <IoIosArrowForward />
          )}
        </p>
        <div
          className={`collapse ${openMenu === "menuRoutes" ? "show" : ""}`}
          id="menuRoutes"
        >
          <ul className="list-menu">
            <li className="item">
              <Link to={"/dashboard/new-route"}>Nueva Ruta</Link>
            </li>
            <li className="item">Opción 2</li>
          </ul>
        </div>
      </div>
      <div>
        <p
          className={`menu-item ${
            openMenu === "menuMembership" ? "menu-item-select" : ""
          }`}
          onClick={() => toggleMenu("menuMembership")}
        >
          <span className="d-flex gap-2">
            <MdOutlineCardMembership />
            Membresía
          </span>
          {openMenu === "menuMembership" ? (
            <IoIosArrowDown />
          ) : (
            <IoIosArrowForward />
          )}
        </p>
        <div
          className={`collapse ${openMenu === "menuMembership" ? "show" : ""}`}
          id="menuMembership"
        >
          <ul className="list-menu">
            <li className="item">Opción 1</li>
            <li className="item">Opción 2</li>
            <li className="item">Opción 1</li>
            <li className="item">Opción 2</li>{" "}
            <li className="item">Opción 1</li>
            <li className="item">Opción 2</li>
          </ul>
        </div>
      </div>
      <hr />
      {/*Menú configuración */}
      <div>
        <p
          className={`menu-item ${
            openMenu === "menuSettings" ? "menu-item-select" : ""
          }`}
          onClick={() => toggleMenu("menuSettings")}
        >
          <span className="d-flex gap-2">
            <IoSettings />
            Configuración
          </span>
          {openMenu === "menuSettings" ? (
            <IoIosArrowDown />
          ) : (
            <IoIosArrowForward />
          )}
        </p>
        <div
          className={`collapse ${openMenu === "menuSettings" ? "show" : ""}`}
          id="menuSettings"
        >
          <ul className="list-menu">
            <li className="item">Perfil</li>
            <li className="item">Cuenta</li>
            <li className="item">
              <Link onClick={() => dispatch(logOut())}>Cerrar Sesion</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;
