import React from 'react';
import './cardTestimonial.css';
//Assets
import QuoteMark from '../../assets/icons/quotation_marks.svg';
import ImgProfile1 from '../../assets/images/User1.png';
import Star from '../../assets/icons/star.svg';

const CardTestimonial = ({ dataTestiomonial }) => {
  const getScore = (score) => {
    let totalScore = [];
    for (let i = 0; i < score; i++) {
      let key = Math.random();
      totalScore.push(<img src={Star} alt="start" key={key} />);
    }
    return totalScore;
  };

  return (
    <div className="card-testimonial text-info" key={dataTestiomonial.usrId}>
      <div className="card-body">
        <span>
          <img src={QuoteMark} alt="QuoteMark" />
        </span>
        <p className="">{dataTestiomonial.comment}</p>
        <div className="wrap-score">
          {getScore(dataTestiomonial.score)?.map((score) => {
            return score;
          })}
        </div>
      </div>
      <hr />
      <div className="card-footer">
        <img src={ImgProfile1} alt="img user" />
        <h3>{dataTestiomonial.nameUser}</h3>
      </div>
    </div>
  );
};

export default CardTestimonial;
