import "./styles/home-styles.css";
import React from "react";
//Assets
//Components
import Navbar from "../../components/navbar/navbar.jsx";
import ButtonLink from "../../components/ButtonLink/ButtonLink.jsx";
import AboutHome from "./AboutHome.jsx";
import WelcomeHome from "./WelcomeHome.jsx";
import ServicesHome from "./ServicesHome.jsx";
import Footer from "../../components/footer/Footer.jsx";
import ProgressBarRunning from "../../components/ProgressBarRunning/ProgressBarRunning.jsx";
import Testiomonials from "./Testimonials.jsx";
import CarouselHome from "./CarouselHome.jsx";
import PreviewApp from "./PreviewApp.jsx";
import PreviewRoutes from "./PtrviewRoutes.jsx";
// import { useNavigation } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div>
        <div className="container_home_bg">
          <Navbar />
          <div className="container_content">
            <div className="d-flex justify-content-center flex-column">
              <h1 className="title animate__animated animate__backInLeft animate__slow">
                ¿Qué es Radaria y qué beneficios tiene?
              </h1>
              <p className="animate__animated animate__backInLeft animate__slow">
                Cada carrera es una oportunidad para superarte. Conecta con tu
                pasión, sigue tu progreso y alcanza nuevas metas acompañado de
                una comunidad que te inspira.
              </p>
              <div className="d-flex">
                <ButtonLink link="/auth" Text="¡Vamos!" isAvctive={true} />
                <ButtonLink link="/about-us" Text="Más..." isAvctive={false} />
              </div>
            </div>
          </div>
          <div className="d-flex container_usersSign ">
            <div className="d-flex flex-column align-items-end">
              <div className="number_users animate__animated animate__swing animate__infinite ">
                <p>+30K</p>
              </div>
              <p className="d-flex ">Usuarios se han inscrito.</p>
            </div>
          </div>
        </div>
        <div className="content bg-black">
          <WelcomeHome />
          <AboutHome />
          <ServicesHome />
          <PreviewApp />
          <CarouselHome />
          <PreviewRoutes />
          <ProgressBarRunning />
          <Testiomonials />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
