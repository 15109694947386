import React from 'react';
import './styles/preview-routes.css';
//Assets
import MapUsers from '../../assets/images/MapUsers.png';
import CardRouteChapu from '../../assets/images/CardRouteChapu.png';
import CardRoutePolanco from '../../assets/images/CardRoutePolanco.png';
import ProfileRoutes from '../../assets/images/MapRoutesUser.png';
import CardRadarPoints from '../../assets/images/CardRadarPointRunning.png';
import CardRadarPointSotelo from '../../assets/images/CardRadarPointSotelo.png';
import ProfileRunningPoints from '../../assets/images/ProfileRadarPints.png';
import CardProgress from '../../assets/images/CardUserProgress.png';
import ProfileUserProgress from '../../assets/images/ProfileUser.png';

const PreviewRoutes = () => {
  return (
    <section className="wrap-preview-routes">
      <div>
        <aside>
          <h3 className="text-primary">Conoce Runnuers</h3>
          <p className="text-info">
            Haz que cada kilómetro cuente: rastrea tu progreso y supera tus
            límites.
          </p>
          <img src={MapUsers} alt="Map of users" />
        </aside>
        <aside>
          <h3 className="text-primary">Califica Rutas</h3>
          <article>
            <div>
              <img src={CardRouteChapu} alt="Route of Chapu" />
              <img src={CardRoutePolanco} alt="Route of Polanco" />
            </div>
            <img src={ProfileRoutes} alt="Profile of Routes of usuario" />
          </article>
        </aside>
      </div>
      <div>
        <aside>
          <h3 className="text-primary"> Condigue Descuentos Especiales </h3>
          <article>
            <div>
              <img src={CardRadarPointSotelo} alt="Radar Point Sotelo" />
              <img src={CardRadarPoints} alt="Radar Point Running" />
            </div>
            <img
              src={ProfileRunningPoints}
              alt="Profile of Routes of usuario Radar Points"
            />
          </article>
        </aside>
        <aside>
          <h3 className="text-primary">Sigue tu proceso</h3>
          <article>
            <img src={CardProgress} alt="Card your progress" />
            <img
              src={ProfileUserProgress}
              alt="Profile of User of his progress"
            />
          </article>
        </aside>
      </div>
    </section>
  );
};
export default PreviewRoutes;
