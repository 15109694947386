// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-link {
  box-shadow: 0px 0px 5px 0px var(--bs-primary);
  max-width: 150px;
}

.button-link:hover {
  transform: scale(1.2);
}
.active:hover {
  background-color: transparent !important;
  color: var(--bs-info) !important;
}
.inactive:hover {
  background-color: var(--bs-primary) !important;
  color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ButtonLink/buttonLink.css"],"names":[],"mappings":"AAEA;EACE,6CAA6C;EAC7C,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,wCAAwC;EACxC,gCAAgC;AAClC;AACA;EACE,8CAA8C;EAC9C,uBAAuB;AACzB","sourcesContent":["@import '.../../../../assets/styles/global.css';\n\n.button-link {\n  box-shadow: 0px 0px 5px 0px var(--bs-primary);\n  max-width: 150px;\n}\n\n.button-link:hover {\n  transform: scale(1.2);\n}\n.active:hover {\n  background-color: transparent !important;\n  color: var(--bs-info) !important;\n}\n.inactive:hover {\n  background-color: var(--bs-primary) !important;\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
