// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-loading {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.747);
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100vh;
  z-index: 1000;
}

.spinner-grow {
  position: absolute;
  top: 40%;
  left: 50%;
}

.hidden {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,sCAAsC;EACtC,QAAQ;EACR,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,aAAa;AACf","sourcesContent":["@import \"../../assets/styles/global.css\";\n\n.container-loading {\n  position: absolute;\n  background-color: rgba(0, 0, 0, 0.747);\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  min-height: 100vh;\n  z-index: 1000;\n}\n\n.spinner-grow {\n  position: absolute;\n  top: 40%;\n  left: 50%;\n}\n\n.hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
