import React from "react";
import "./styles/welcome-home.css";
//Assets
import RunnerBorder from "../../assets/images/Running-border.png";
import QuotationMarks from "../../assets/icons/quotation_marks.svg";
//Components
import ProgressBar from "../../components/progressbar/ProgressBar";
import StepsToFollow from "../../components/StepsToFollow/StepsToFollow";
import ReadySetGo from "../../components/ReadySetGo/ReadySetGo";
import ScrollAnimation from "react-animate-on-scroll";

const WelcomeHome = () => {
  return (
    <>
      <div className="container-fluid bg-dark">
        <div className="container-progress">
          <ProgressBar />
        </div>
        <div className="container-fluid welcome-bg d-flex align-items-center">
          <div className="container-text ms-lg-5 ps-lg-5 p-3">
            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              <h1>
                Bienvenido a{" "}
                <span id="text-radaria" className="">
                  Radaria
                </span>
              </h1>
            </ScrollAnimation>
            <div className="container-btn">
              <div className="divider-linear"></div>
              <ScrollAnimation
                animateIn="animate__bounceInLeft"
                animateOut="animate__bounceInLeft"
              >
                <button
                  type="button"
                  className="btn btn-primary rounded-pill pe-4 ps-4"
                >
                  Conoce más...
                </button>
              </ScrollAnimation>
            </div>
            <p className="mt-3">
              Fomentamos un entorno que inspira y motiva a los corredores a
              lograr sus objetivos y superar su límites
            </p>
          </div>
        </div>
        <div className="container mt-5 mb-5">
          <div className="row d-flex align-items-center">
            <div className="mx-auto container-img d-flex justify-content-center  col-lg-5 col-md-6">
              <ScrollAnimation
                animateIn="animate__bounceInLeft"
                animateOut="animate__bounceOutLeft"
              >
                <img
                  className="mx-auto d-block"
                  src={RunnerBorder}
                  alt="Runner Radaria"
                />
              </ScrollAnimation>
            </div>
            <div className="col-lg-7 col-md-6 container-parOfRadaria">
              <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
                <h2 className="d-flex justify-content-center text-info">
                  Se parte de
                  <b className="ms-1 text-primary">Radaria</b>!
                </h2>
              </ScrollAnimation>
              <div className="d-flex">
                <img src={QuotationMarks} alt="quotation-marks" />
                <p className="text-info fs-4">
                  Transforma tu pasión por correr con nuestro soporte integral:
                  rutas, nutrición, fisioterapia y comunidad.
                </p>
              </div>
              <StepsToFollow />
              <div className=" container-readySetGo mt-4">
                <ReadySetGo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WelcomeHome;
