import axios from "axios";
import { store } from "../redux/store";
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});
apiClient.interceptors.request.use(
  async (config) => {
    const userData = store.getState().authReducer;
    const token = userData.token;

    const noAuthRoutes = ["users/newUser", "login/auth"];
    const isAuthRoute = !noAuthRoutes.includes(
      config.url.replace(config.baseURL, "")
    );

    if (token && token !== "null" && isAuthRoute) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Configuración dinámica de Content-Type si se envía FormData
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
