import React from "react";
import { FiSearch } from "react-icons/fi";
import "./styles/searchbar.css";

const SearchBar = () => {
  return (
    <div className=" search-bar mt-2">
      <FiSearch className="search-btn" />
      <input
        type="text"
        className="form-control"
        placeholder="Buscar..."
        id="searchBar"
      />
    </div>
  );
};

export default SearchBar;
