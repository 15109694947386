import React from "react";
import "./styles/metricsSection.css";
import MetricsCard from "../../../../components/MetricsCard/MetricsCard";

const MetricsSection = () => {
  return (
    <div className="metrics-section">
      <MetricsCard
        title="Distancia"
        value="50.8 KM"
        change="28.4%"
        changeType="up"
      />
      <MetricsCard
        title="Rutas"
        value="2 Rutas"
        change="12.6%"
        changeType="down"
      />
      <MetricsCard
        title="Long Run"
        value="1 KM"
        change="3.1%"
        changeType="up"
      />
      <MetricsCard
        title="Terreno"
        value="Pista"
        change="3.1%"
        changeType="up"
      />
    </div>
  );
};

export default MetricsSection;
