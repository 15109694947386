import React from "react";
import "./styles/radarPoints.css";
import LineRadaria from "../../assets/icons/LineRadaria.svg";
import Running from "../../assets/icons/Runner.svg";
import GroupRunner from "../../assets/images/GroupRunners.png";
import CardCommunity from "./CardCommunity";
import RunningClub from "../../assets/images/temporalImages/RunningCLub.png";
const listRunningProfesionl = [
  {
    name: "Artimega",
    image: RunningClub,
    description: "Café ideal para runners",
    locationUrl: "",
  },
  {
    name: "Artimega",
    image: RunningClub,
    description: "Café ideal para runners",
    locationUrl: "",
  },
  {
    name: "Artimega",
    image: RunningClub,
    description: "Café ideal para runners",
    locationUrl: "",
  },
  {
    name: "Artimega",
    image: RunningClub,
    description: "Café ideal para runners",
    locationUrl: "",
  },
];
const listRunnerAmateur = [
  {
    name: "Nike",
    image: RunningClub,
    description:
      "Ropa deportiva con descuentos exclusivos a comunidad de radaria",
    locationUrl: "",
  },
  {
    name: "Nike",
    image: RunningClub,
    description:
      "Ropa deportiva con descuentos exclusivos a comunidad de radaria",
    locationUrl: "",
  },
  {
    name: "Nike",
    image: RunningClub,
    description:
      "Ropa deportiva con descuentos exclusivos a comunidad de radaria",
    locationUrl: "",
  },
  {
    name: "Nike",
    image: RunningClub,
    description:
      "Ropa deportiva con descuentos exclusivos a comunidad de radaria",
    locationUrl: "",
  },
];

const ComnmunitiesAndEvents = () => {
  return (
    <section className="wrap-radar-points wrap-Rinning-club">
      <div className="wrap-description-radar-points">
        <div>
          <div className="wraper-title-radar-points">
            <img src={LineRadaria} alt="line" width={60} />
            <h2 className="text-white text-center">Runnings Clubs</h2>
            <img src={Running} alt="" />
          </div>
          <p className="text-white text-center">
            Correr en grupo te llevará más lejos. Únete a los Running Clubs y
            forma parte de una comunidad que te impulsa a alcanzar nuevas metas.
          </p>
        </div>
        <div className="container-img-map">
          <img src={GroupRunner} alt="" />
        </div>
      </div>
      <div className="d-flex flex-column gap-4 ">
        <div className="container-tile-service">
          <div></div>
          <h2 className="text-white text-center">Profesionales</h2>
          <div></div>
        </div>
        <div className="wrap-cards-radar-points">
          {listRunningProfesionl.map((club, index) => (
            <CardCommunity key={index} dataService={club} />
          ))}
        </div>
      </div>
      <div className="d-flex flex-column gap-4 ">
        <div className="container-tile-service">
          <div></div>
          <h2 className="text-white text-center">Amateur</h2>
          <div></div>
        </div>
        <div className="wrap-cards-radar-points">
          {listRunnerAmateur.map((club, index) => (
            <CardCommunity key={index} dataService={club} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ComnmunitiesAndEvents;
