import React from "react";
import "./styles/sidebar.css";
import logo from "../../assets/images/logotipos/RADARIA-LOGOTIPO04.png";
import SearchBar from "./SearchBar";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { RiMapPinAddFill } from "react-icons/ri";
import Menu from "./Menu";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div
      className={`sidebar ${
        isOpen ? "open" : ""
      } d-flex flex-column  p-4 bg-dark text-white`}
    >
      <section className="d-flex justify-content-between mt-3 mb-3 ">
        <div>
          <img
            src={logo}
            alt="radaria logo"
            style={{ width: "150px", height: "auto" }}
          />
        </div>
        <IoClose className="close-btn" onClick={toggleSidebar} />
      </section>

      <SearchBar />

      <section className="mt-3">
        <Menu />
      </section>
      <div className="">
        <Link
          to="#"
          className="d-flex align-items-center text-white text-decoration-none"
        >
          <img
            src="https://via.placeholder.com/32"
            alt=""
            width="32"
            height="32"
            className="rounded-circle me-2"
          />
          <strong className="d-flex align-items-end">John Carter</strong>
        </Link>
      </div>
      <button className="btn btn-primary mt-3 w-100">
        Crear Ruta <RiMapPinAddFill />
      </button>
    </div>
  );
};

export default Sidebar;
