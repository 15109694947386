export const calcPersentage = (total, value) => {
  const persentage = (value / total) * 100;
  return persentage.toFixed(1);
};

export const convertToMinutes = (timeStr) => {
  let totalMinutes = 0;
  const timeParts = timeStr.split(" ");

  for (let i = 0; i < timeParts.length; i += 2) {
    const value = parseInt(timeParts[i]);
    const unit = timeParts[i + 1];

    if (unit.includes("h")) {
      totalMinutes += value * 60;
    } else if (unit.includes("min")) {
      totalMinutes += value;
    }
  }

  return totalMinutes;
};

export const calculateTotalRunningTime = (routes) => {
  return routes.reduce((total, route) => {
    if (route.complete) {
      return total + convertToMinutes(route.estimatedTime);
    }
    return total;
  }, 0);
};
export const calculateTotalRunningTimeRoutes = (routes) => {
  return routes.reduce((total, route) => {
    return total + convertToMinutes(route.estimatedTime);
  }, 0);
};

export const routesCompleted = (routes) => {
  return routes.filter((route) => route.complete).length;
};
