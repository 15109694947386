// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wraper-card-users {
  border-radius: 20px;
  background: var(
    --Variant-Transparent,
    linear-gradient(
      90deg,
      rgba(212, 216, 216, 0.32) 99.99%,
      rgba(112, 114, 114, 0.32) 100%
    )
  );
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
  width: 254px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wraper-card-users > header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'CodecProregular';
}
.wraper-card-users > header > img {
  vertical-align: middle;
}
.total-users {
  color: var(--bs-transparentColor);
  font-size: 14px;
}
.wraper-card-users > section {
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: end;
}
.wraper-card-users > section > img {
  border-radius: 50%;
  margin-right: -10px;
}
.more-users {
  background-color: var(--bs-primary);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/AvatarGroup/avatarGroup.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB;;;;;;;GAOC;EACD,mCAA2B;UAA3B,2BAA2B;EAC3B,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,8BAA8B;AAChC;AACA;EACE,sBAAsB;AACxB;AACA;EACE,iCAAiC;EACjC,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,mCAAmC;EACnC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".wraper-card-users {\n  border-radius: 20px;\n  background: var(\n    --Variant-Transparent,\n    linear-gradient(\n      90deg,\n      rgba(212, 216, 216, 0.32) 99.99%,\n      rgba(112, 114, 114, 0.32) 100%\n    )\n  );\n  backdrop-filter: blur(12px);\n  width: 254px;\n  height: 120px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.wraper-card-users > header {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  font-family: 'CodecProregular';\n}\n.wraper-card-users > header > img {\n  vertical-align: middle;\n}\n.total-users {\n  color: var(--bs-transparentColor);\n  font-size: 14px;\n}\n.wraper-card-users > section {\n  margin-top: 20px;\n  position: relative;\n  display: flex;\n  justify-content: flex-start;\n  align-items: end;\n}\n.wraper-card-users > section > img {\n  border-radius: 50%;\n  margin-right: -10px;\n}\n.more-users {\n  background-color: var(--bs-primary);\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
