import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/global.css";

import mapboxgl from "mapbox-gl";
import ReduxProvider from "./redux/provider";
import { Toaster } from "react-hot-toast";
import ModalAlert from "./components/ModalAlert/ModalAlert";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWRtaW4tcmFkYXJpYSIsImEiOiJjbHp2eTM0NjMwY3dtMmpwdjBkMzl2OHQwIn0.bcCiBJuWpvITvalXMIl9kQ";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReduxProvider>
        <ModalAlert />
        <Toaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{
            top: 100,
            left: 20,
            bottom: 20,
            right: 50,
          }}
          toastOptions={{
            success: {
              style: {
                background: "rgba(0, 0, 0, 50%)",
                color: "white",
              },
            },
            error: {
              style: {
                background: "rgba(0, 0, 0, 50%)",
                color: "white",
              },
            },
          }}
        />
        <App />
      </ReduxProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
