import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

//Assets
import btnTop from '../../assets/images/ButtonTop.webp';
import LogoRadaria from '../../assets/images/logotipos/RADARIA-LOGOTIPO01.png';
import QR from '../../assets/images/QR.jpg';
import FaceboockIcon from '../../assets/icons/facebook-square.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import IconWhatsapp from '../../assets/icons/icon _whatsapp.svg';
import IconMapMarker from '../../assets/icons/icon _Map Marker.png';
import IconEmail from '../../assets/icons/icon_email.png';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <footer className="">
      <article className="container-downloadApp bg-black pt-3 pb-3">
        <div className="d-flex flex-column align-items-center wrap-footer-qr">
          <p className="text-info fs-4">Descarga la App</p>
          <img src={QR} alt="Qr" width={'50%'} />
          <div className="m-3">
            <Link className="m-1">
              <img src={FaceboockIcon} alt="" />
            </Link>
            <Link className="m-1">
              <img src={InstagramIcon} alt="" />
            </Link>
            <Link className="m-1 fs-4">@radariarun</Link>
          </div>
        </div>
        <div className="wrap-form-footer">
          <img src={LogoRadaria} alt="" />
          <p className="text-info fs-4">Suscríbete a nuestro blog</p>
          <form className="d-flex flex-column">
            <input
              type="text"
              name="email"
              id="email"
              placeholder="email@ejemplo.com"
              className="form-control text-info m-2"
            />
            <button className="btn btn-primary m-2">Registrarme</button>
          </form>
        </div>
      </article>
      <section className="wraper-info-footer">
        <div className="wraper-nav">
          <div className="subwraper-nav">
            <Link>INICIO</Link>
            <Link>NOSOTROS</Link>
            <Link>MEMBRESIAS</Link>
            <Link>INICIAR SESIÓN</Link>
          </div>
          <div className="subwraper-nav">
            <Link>ENTRENADORES</Link>
            <Link>SRVICIOS</Link>
            <Link>COMUNIDAD</Link>
          </div>
        </div>
        <div className="wraper-contact">
          <Link className="m-1">
            <img src={IconWhatsapp} alt="" className="me-1" />
            +52 5615 124 589
          </Link>
          <Link className="m-1">
            <img src={IconMapMarker} alt="" className="me-1" />
            Polanco, CDM
          </Link>
          <Link className="m-1">
            <img src={IconEmail} alt="" className="me-1" />
            radaria@radaria.mx
          </Link>
        </div>
      </section>
      <div className="bg-primary d-flex wraper-license justify-content-center">
        <span className="text-dark m-2 ">
          &copy;2024 Radaria. All rights reserverd
        </span>
      </div>
      <button className="rounded-circle btn" onClick={scrollToTop}>
        <img src={btnTop} alt="Icon btn top" />
      </button>
    </footer>
  );
};

export default Footer;
