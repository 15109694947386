import React from "react";
import "./styles/radarPoints.css";
import LineRadaria from "../../assets/icons/LineRadaria.svg";
import { FaMapMarkerAlt } from "react-icons/fa";
import MapRadarPints from "../../assets/images/MapRadarPoints.png";
import CardCommunity from "./CardCommunity";
import CoffeImg from "../../assets/images/temporalImages/2(25).png";
const listCoffeShop = [
  {
    name: "Artimega",
    image: CoffeImg,
    description: "Café ideal para runners",
    locationUrl: "",
  },
  {
    name: "Artimega",
    image: CoffeImg,
    description: "Café ideal para runners",
    locationUrl: "",
  },
  {
    name: "Artimega",
    image: CoffeImg,
    description: "Café ideal para runners",
    locationUrl: "",
  },
  {
    name: "Artimega",
    image: CoffeImg,
    description: "Café ideal para runners",
    locationUrl: "",
  },
];
const listOfferts = [
  {
    name: "Nike",
    image: CoffeImg,
    description:
      "Ropa deportiva con descuentos exclusivos a comunidad de radaria",
    locationUrl: "",
  },
  {
    name: "Nike",
    image: CoffeImg,
    description:
      "Ropa deportiva con descuentos exclusivos a comunidad de radaria",
    locationUrl: "",
  },
  {
    name: "Nike",
    image: CoffeImg,
    description:
      "Ropa deportiva con descuentos exclusivos a comunidad de radaria",
    locationUrl: "",
  },
  {
    name: "Nike",
    image: CoffeImg,
    description:
      "Ropa deportiva con descuentos exclusivos a comunidad de radaria",
    locationUrl: "",
  },
];

const RadarPoints = () => {
  return (
    <section className="wrap-radar-points">
      <div className="wrap-description-radar-points">
        <div>
          <div className="wraper-title-radar-points">
            <img src={LineRadaria} alt="line" width={60} />
            <h2 className="text-white text-center">
              Radar P<FaMapMarkerAlt className="text-primary fs-3" />
              ints
            </h2>
          </div>
          <p className="text-white text-center">
            Encuentra en los Radar Points el lugar perfecto para compartir tu
            pasión por correr, aprender y crecer junto a otros corredores.
          </p>
        </div>
        <div className="container-img-map">
          <img src={MapRadarPints} alt="" />
        </div>
      </div>
      <div className="d-flex flex-column gap-4 ">
        <div className="container-tile-service">
          <div></div>
          <h2 className="text-white text-center">Cafeterias</h2>
          <div></div>
        </div>
        <div className="wrap-cards-radar-points">
          {listCoffeShop.map((coffeshop, index) => (
            <CardCommunity key={index} dataService={coffeshop} />
          ))}
        </div>
      </div>
      <div className="d-flex flex-column gap-4 ">
        <div className="container-tile-service">
          <div></div>
          <h2 className="text-white text-center">Marcas Aliadas</h2>
          <div></div>
        </div>
        <div className="wrap-cards-radar-points">
          {listOfferts.map((offert, index) => (
            <CardCommunity key={index} dataService={offert} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default RadarPoints;
