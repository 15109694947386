import React, { useState } from "react";
import "./createAccount.css";
//Components
import Navbar from "../../components/navbar/navbar";
//Assets

import FormSigIn from "./FormSigIn/ForrmSigIn";
import FormSignUp from "./FormSignUp/FormSignUp";

const CreateAccount = () => {
  const [isSignUp, setIsSignUp] = useState(false);

  return (
    <div className="container_login">
      <Navbar />
      <div className="container d-flex">
        <div className="container_form  p-3">
          <div className="header">
            <span
              className={`tab ${!isSignUp ? "active" : ""}`}
              onClick={() => {
                setIsSignUp(false);
              }}
            >
              Iniciar Sesión
            </span>
            <span
              className={`tab ${isSignUp ? "active" : ""}`}
              onClick={() => setIsSignUp(true)}
            >
              Crear cuenta
            </span>
          </div>
          <div
            className={`d-flex form-container ${
              isSignUp ? "right-panel-active" : "right-panel-desactive"
            } `}
          >
            <div className="form sign-in-form">
              <FormSigIn />
            </div>
            <div className="form sign-up-form">
              <FormSignUp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
