import React from "react";
import "./validationExpired.css";

const ValidationExpired = () => {
  return (
    <div className="container_verified ">
      <section className="subwraper">
        <h1 className="text-center text-white fs-4">
          ¡Expiró el periodo de verificacion!
        </h1>
        <h1 className="text-center text-white fs-4">
          ¡El enlace caduco!
        </h1>
        <div className="d-flex flex-column align-items-center gap-1">
          <span className="text-center text-white">
            Reenvie su email para solicitar una nueva verificación
          </span>
        </div>
        <div className="">
          <input
            type="email"
            className="form-control text-light"
            id="inputEmailLogin"
            placeholder="name@example.com"
          />
        </div>
        <button className="btn btn-primary ">Reenviar</button>
      </section>
    </div>
  );
};

export default ValidationExpired;
