import React from "react";
import icono1 from "../../assets/images/about.png";
import icono2 from "../../assets/images/about2.png";
import icono3 from "../../assets/images/about3.png";
import icono4 from "../../assets/images/about4.png";
import RadariaFeed from "../../assets/images/RADARIA FEED.png";
import "../../Views/Home/styles/aboutHome-styles.css";

const AboutHome = () => {
  return (
    <>
      <div className="bg-black container-fluid justify-content-center">
        <div className="row container-card-users justify-content-center border-primary border-top border-bottom pt-4 pb-4">
          <div className="col-6  d-flex flex-column flex-md-row justify-content-center">
            <div className="col-md-6 d-flex flex-column align-items-center">
              <img
                src={icono1}
                alt="Icono 1"
                style={{ maxWidth: "200px" }}
                className="img-fluid mb-2"
              />
              <div className="users-count">
                <p className="number">+200</p>
                <p className="label">Campeones</p>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center">
              <img
                src={icono2}
                alt="Icono 2"
                style={{ maxWidth: "200px" }}
                className="img-fluid mb-2"
              />
              <div className="users-count">
                <p className="number">+250</p>
                <p className="label"> Entrenadores </p>
              </div>
            </div>
          </div>
          <div className="col-6 d-flex flex-column flex-md-row justify-content-center">
            <div className="col-md-6 d-flex flex-column align-items-center">
              <img
                src={icono3}
                alt="Icono 3"
                style={{ maxWidth: "200px" }}
                className="img-fluid mb-2"
              />
              <div className="users-count">
                <p className="number">+30k</p>
                <p className="label"> Usuarios </p>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center">
              <img
                src={icono4}
                alt="Icono 4"
                style={{ maxWidth: "200px" }}
                className="img-fluid mb-2"
              />
              <div className="users-count">
                <p className="number">+500</p>
                <p className="label"> Rutas </p>
              </div>
            </div>
          </div>
        </div>

        {/**Seccion de contenido */}
        <div className="row mt-3 contenido-bg">
          <div className="col-md-6 d-flex flex-column align-items-center container-deg">
            <h2 className="title text-center text-info">
              {" "}
              Conoce más sobre <br />{" "}
              <span className="text-secondary">Radaria</span>
            </h2>
            <p className="description text-info text-center">
              El isotipo muestra de forma abstracta la pista de atletismo
              combinando la “R” de RADARIA. Esto se utiliza como símbolo de la
              marca, transmitiendo la idea de que la aplicación está diseñada
              para ser fácil de usar, y para todos aquellos que recién se
              inician o ya llevan un tiempo en el deporte. Esto es poderoso para
              la identidad de marca, ya que puede ser identificable
              instantáneamente.
            </p>
          </div>
          <div className="col-md-6 text-center">
            <div className="image-wrapper d-inline-block p-4 ">
              <img
                src={RadariaFeed}
                alt="Radaria Feed"
                className="img-container"
              />
            </div>
          </div>
        </div>
        <div className="container-phrase">
          <p className="text-info text-center">
            "La meta no es el final, es solo el comienzo de un nuevo desafío."
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutHome;
