import React from 'react';
import './avatarGroup.css';
//Assets
import User1 from '../../assets/images/User1.png';
import User2 from '../../assets/images/User2.png';
import User3 from '../../assets/images/User3.png';
import User4 from '../../assets/images/User4.png';
import MapMark from '../../assets/icons/MapMarker.svg';

const AvatarGroup = () => {
  const listUsers = [
    {
      id: 1,
      photo: User1,
    },
    {
      id: 2,
      photo: User2,
    },
    {
      id: 3,
      photo: User3,
    },
    {
      id: 4,
      photo: User4,
    },
  ];

  return (
    <article className="wraper-card-users text-info">
      <header>
        <span className="me-3">
          <img src={MapMark} alt="" className="me-2" />
          Polanco, CDMX
        </span>
        <span className="total-users ">+300 users</span>
      </header>
      <section>
        {listUsers.map((user) => (
          <img src={user.photo} key={user.id} alt="userImage" />
        ))}
        <div className="more-users text-black">+30</div>
      </section>
    </article>
  );
};
export default AvatarGroup;
