import React from 'react';
import './section-aboutus.css';

//Components
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import ScrollAnimation from 'react-animate-on-scroll';
//Assets
import ImageRunner from '../../../assets/images/Runner-AboutUs.png';

const SectionAboutUs = () => {
  return (
    <>
      <div className="line-separator"></div>
      <section className="wraper-section-about">
        <article>
          <ScrollAnimation animateIn="backInLeft" animateOut="backOutLeft">
            <img src={ImageRunner} alt="Runner" className="" />
          </ScrollAnimation>
        </article>
        <aside className="text-info">
          <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
            <h2>
              Conoce un poco más de <span>Radaria</span>
            </h2>
            <p>
              En Radaria creemos que correr es más que un deporte, es una forma
              de conectar con nosotros mismos y con los demás. Nuestro propósito
              es crear una comunidad donde los corredores de todos los niveles
              puedan encontrar inspiración, apoyo y las herramientas necesarias
              para superar sus límites.
            </p>
            <div>
              <ButtonLink Text={'¡Vamos!'} isAvctive={true} link="/login" />
              <ButtonLink
                Text={'Comunidad'}
                isAvctive={false}
                link="/comunity"
              />
              <ButtonLink
                Text={'Servicios'}
                isAvctive={false}
                link="/services"
              />
            </div>
            <p>
              Somos una comunidad global unida por nuestra pasión por correr,
              búsqueda de superación personal y deseo de conectar con otros que
              comparten la misma pasión.
            </p>
          </ScrollAnimation>
        </aside>
      </section>
      <div className="line-separator"></div>
    </>
  );
};

export default SectionAboutUs;
