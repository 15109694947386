import './progressBarRunning.css';
import React, { useState } from 'react';
import RunningImage from '../../assets/icons/runnersIcon.svg';
import startMark from '../../assets/icons/StartMark.svg';
import finishMark from '../../assets/icons/FinishMark.svg';
import CoffeMarker from '../../assets/icons/CoffeRunning.svg';

const ProgressBarRunning = () => {
  // Estado para controlar la posición del corredor
  const [position, setPosition] = useState(0);

  // Función para manejar el movimiento del corredor
  const handleMove = (event) => {
    const track = event.currentTarget.getBoundingClientRect();
    const newPosition = event.clientX - track.left;
    setPosition(newPosition);
  };

  return (
    <div className="track-container">
      <div className="point point-start">
        <img src={finishMark} alt="" />
      </div>
      <div className="runner-track" onMouseMove={handleMove}>
        <div className="line-runner"></div>
        <div className="runner" style={{ left: `${position}px` }}>
          <img src={RunningImage} alt="" />
        </div>
      </div>
      <div className="point point-middle">
        <img src={CoffeMarker} alt="" />
      </div>
      <div className="point point-end">
        <img src={startMark} alt="" />
      </div>
    </div>
  );
};
export default ProgressBarRunning;
