import React from "react";
import "./cardMembership.css";
import { Link } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import IconCircleMembership from "../../assets/icons/iconMembership_Black.svg";
import IconCircleMembershipSelected from "../../assets/icons/iconMembership_Selected.svg";

const CardMembership = ({
  _id,
  planName,
  price,
  currency,
  description,
  selected,
  setSelected,
}) => {
  return (
    <div
      className="col-sm-3 col-9 mb-3 mb-sm-0 m-md-3  card-membership card  text-white"
      onClick={() => setSelected(_id)}
    >
      <div className="card-body ">
        {selected ? (
          <img src={IconCircleMembershipSelected} alt="Icon" width={30} />
        ) : (
          <img src={IconCircleMembership} alt="Icon" width={30} />
        )}

        <h5 className="card-title text-start">{planName}</h5>
        <section className="d-flex align-items-center justify-content-start">
          <h2 className="fs-1">${price} </h2>
          <span className="fw-light"> {currency} /mes</span>
        </section>

        <Link
          to="/dashboard"
          className={`btn ${
            selected ? "btn-primary" : "btn-dark"
          } btn-membership me-3 ms-3 mb-2`}
        >
          Elegir este plan
        </Link>
        <hr />
        <section className="justify-content-start">
          <p className="text-start">¿Qué incluye?</p>
          <ul className="list-group">
            {description.map((item, index) => {
              return (
                <li
                  className="d-flex justify-content-start align-items-center"
                  key={index}
                >
                  <CiCircleCheck className="me-1" />
                  {item}
                </li>
              );
            })}
          </ul>
        </section>
      </div>
    </div>
  );
};
export default CardMembership;
