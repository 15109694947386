// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graphs-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  gap: 20px;
}
.main-graph {
  border-radius: 10px;
  padding-right: 20px;
  background-color: var(--bs-transparentColor);
  width: 100%;
}
.side-charts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.small-chart {
  background-color: var(--bs-transparentColor);
  padding: 10px;
  border-radius: 10px;
  height: auto;
}

@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 1024px) {
  .graphs-container {
    flex-direction: row;
  }
  .main-graph {
    width: 60%;
  }
  .side-charts {
    width: 40%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Dashboard/pagesUser/HistoryActivity/styles/graphicsSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,4CAA4C;EAC5C,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;AACA;EACE,4CAA4C;EAC5C,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;AACA;AACA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".graphs-container {\n  display: flex;\n  flex-direction: column;\n  border-radius: 10px;\n  width: 100%;\n  gap: 20px;\n}\n.main-graph {\n  border-radius: 10px;\n  padding-right: 20px;\n  background-color: var(--bs-transparentColor);\n  width: 100%;\n}\n.side-charts {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 100%;\n}\n.small-chart {\n  background-color: var(--bs-transparentColor);\n  padding: 10px;\n  border-radius: 10px;\n  height: auto;\n}\n\n@media only screen and (min-width: 768px) {\n}\n@media only screen and (min-width: 1024px) {\n  .graphs-container {\n    flex-direction: row;\n  }\n  .main-graph {\n    width: 60%;\n  }\n  .side-charts {\n    width: 40%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
