// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-container-benefits {
  background-color: black;
  width: 100%;
  z-index: 100;
}
.sectionBenefits {
  width: 80%;
  display: grid;
  gap: 2.5rem;
  padding: 2rem;
  grid-template-columns: 1fr;
}
.cardBenefits {
  border-radius: 25px;
  border-radius: 25px;
  background: linear-gradient(
    107deg,
    rgba(212, 216, 216, 0.16) 2.04%,
    rgba(212, 216, 216, 0.02) 60.54%,
    rgba(212, 216, 216, 0.12) 80.05%
  );
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.3s ease;
}
.cardBenefits > img {
  width: 40px;
  height: 45px;
}
.cardBenefits:hover {
  transform: translateY(-10px);
  border: 0.5px solid var(--bs-primary);
}

@media only screen and (min-width: 768px) {
  .sectionBenefits {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Tres columnas en dispositivos medianos y grandes */
    width: 90%;
  }
}
@media only screen and (min-width: 1024px) {
  .sectionBenefits {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Tres columnas en dispositivos medianos y grandes */
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Coaches/styles/sectionBenefits.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;AACA;EACE,UAAU;EACV,aAAa;EACb,WAAW;EACX,aAAa;EACb,0BAA0B;AAC5B;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB;;;;;GAKC;EACD,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,+BAA+B;AACjC;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,4BAA4B;EAC5B,qCAAqC;AACvC;;AAEA;EACE;IACE;;;KAGC,EAAE,qDAAqD;IACxD,UAAU;EACZ;AACF;AACA;EACE;IACE;;;KAGC,EAAE,qDAAqD;EAC1D;AACF","sourcesContent":["@import \"../../../assets/styles/global.css\";\n\n.full-container-benefits {\n  background-color: black;\n  width: 100%;\n  z-index: 100;\n}\n.sectionBenefits {\n  width: 80%;\n  display: grid;\n  gap: 2.5rem;\n  padding: 2rem;\n  grid-template-columns: 1fr;\n}\n.cardBenefits {\n  border-radius: 25px;\n  border-radius: 25px;\n  background: linear-gradient(\n    107deg,\n    rgba(212, 216, 216, 0.16) 2.04%,\n    rgba(212, 216, 216, 0.02) 60.54%,\n    rgba(212, 216, 216, 0.12) 80.05%\n  );\n  padding: 15px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  transition: transform 0.3s ease;\n}\n.cardBenefits > img {\n  width: 40px;\n  height: 45px;\n}\n.cardBenefits:hover {\n  transform: translateY(-10px);\n  border: 0.5px solid var(--bs-primary);\n}\n\n@media only screen and (min-width: 768px) {\n  .sectionBenefits {\n    grid-template-columns: repeat(\n      2,\n      1fr\n    ); /* Tres columnas en dispositivos medianos y grandes */\n    width: 90%;\n  }\n}\n@media only screen and (min-width: 1024px) {\n  .sectionBenefits {\n    grid-template-columns: repeat(\n      3,\n      1fr\n    ); /* Tres columnas en dispositivos medianos y grandes */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
