import apiClient from "../apiClient";
import handleErrorAPI from "../../utils/handleErrorAPI";

export const registerUser = async (userData) => {
  try {
    const response = await apiClient.post("users/newUser", userData);
    console.log(response);
    return {
      status: true,
      data: response.data,
    };
  } catch (error) {
    return handleErrorAPI(error);
  }
};

export const completeRegister = async (formData) => {
  try {
    const response = await apiClient.put("users/completeInformation", formData);
    return {
      status: true,
      data: response.data,
    };
  } catch (error) {
    return handleErrorAPI(error);
  }
};

export const validateSMS = async (userData) => {
  try {
    const response = await apiClient.put("verify/verify-sms", userData);
    return {
      status: true,
      data: response.data,
    };
  } catch (error) {
    return handleErrorAPI(error);
  }
};

export const loginAuth = async (userData) => {
  try {
    const response = await apiClient.post("login/auth", userData);
    console.log(response);
    return {
      status: true,
      data: response.data,
    };
  } catch (error) {
    return handleErrorAPI(error);
  }
};
