import React from "react";
import "./servicesOffered.css";
//Assets
import ReadySetGo from "../../../components/ReadySetGo/ReadySetGo";
import FisioService from "./FisioService";
import NutricionistService from "./NutricionistService";
import PsicologiaService from "./PsicologiaService";
import Coaches from "./CoachesService";
import lineLeft from "../../../assets/images/formas/LineGradientLieft.png";
import lineRight from "../../../assets/images/formas/LineGradient.png";

const ServicesOffered = () => {
  return (
    <section className="wraper-services-offered">
      <FisioService />
      <div className="line-form-left">
        <img src={lineLeft} alt="line" />
      </div>
      <NutricionistService />

      <div className="wraper-phrase">
        <h2 className="text-white fs-1 text-center">
          Radaria: Tu comunidad de running y bienestar{" "}
        </h2>
        <ReadySetGo />
      </div>
      <PsicologiaService />
      <div className="line-form">
        <img src={lineRight} alt="line" />
      </div>

      <Coaches />
    </section>
  );
};

export default ServicesOffered;
