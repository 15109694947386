import React from "react";
import MetricsSection from "./MetricsSection";
import GraphicsSection from "./GraphicsSection";

const HistoryActivity = () => {
  return (
    <div className="container-fluid gap-5">
      <MetricsSection />
      <GraphicsSection />
    </div>
  );
};
export default HistoryActivity;
