// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metrics-card {
  flex: 1 1;
  padding: 15px;
  background: rgba(128, 128, 128, 0.55);
  color: #d4d8d8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinea el contenido a la izquierda */
  text-align: left;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.metrics-card-title {
  font-size: 16px;
  font-family: VisibyCF;
}

.metrics-card .value {
  font-family: "CodecProRegular";
  font-size: 24px;
  margin-bottom: 0;
}

.metrics-card .change {
  font-family: "CodecProRegular";
  font-size: 1rem;
  margin-bottom: 0;
}

.up {
  color: #05c168 !important;
}

.down {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/MetricsCard/metricsCard.css"],"names":[],"mappings":"AAEA;EACE,SAAO;EACP,aAAa;EACb,qCAAqC;EACrC,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAE,uCAAuC;EAChE,gBAAgB;EAChB,2CAA2C;AAC7C;AACA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@import \"../../assets/styles/global.css\";\n\n.metrics-card {\n  flex: 1;\n  padding: 15px;\n  background: rgba(128, 128, 128, 0.55);\n  color: #d4d8d8;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start; /* Alinea el contenido a la izquierda */\n  text-align: left;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);\n}\n.metrics-card-title {\n  font-size: 16px;\n  font-family: VisibyCF;\n}\n\n.metrics-card .value {\n  font-family: \"CodecProRegular\";\n  font-size: 24px;\n  margin-bottom: 0;\n}\n\n.metrics-card .change {\n  font-family: \"CodecProRegular\";\n  font-size: 1rem;\n  margin-bottom: 0;\n}\n\n.up {\n  color: #05c168 !important;\n}\n\n.down {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
