import React from "react";
import "./notFound.css";
import Img404NotFound from "../../assets/images/notFoundImg.png";
import ImgRunnerNotFound from "../../assets/images/runerNotFound.png";
import LogotipoRadaria from "../../assets/images/logotipos/RADARIA-LOGOTIPO04.png";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="continer-not-found">
      <article className="d-flex flex-column container-message-notfound">
        <div>
          <Link to="/" className="d-flex gap-3">
            <FaArrowCircleLeft className="text-white fs-1" />
            <img src={LogotipoRadaria} alt="" width={200} height={40} />
          </Link>
        </div>
        <div className="wrap-message">
          <h2 className="text-white text-center">Oops!</h2>
          <h3 className="text-white text-center">
            Parece que no podemos encontrar la página que estás buscando.
          </h3>
          <img src={ImgRunnerNotFound} alt="" />
        </div>
      </article>
      <article className="container-img-not-found">
        <img src={Img404NotFound} alt="" />
      </article>
    </div>
  );
};

export default NotFound;
