import React from "react";
import "./loading.css";
//Assets
import Isotipo from "../../assets/images/isotipos/RADARIA-ISOTIPO03.webp";
import { useSelector } from "react-redux";

const Loading = () => {
  const { loading } = useSelector((state) => state.loadingReducer);
  return (
    <div className={`container-loading ${loading ? "visible" : "hidden"}`}>
      <div
        className="spinner-grow"
        style={{ width: "5rem", height: "5rem" }}
        role="status"
      >
        <span className="">
          <img
            src={Isotipo}
            alt=""
            style={{ width: "5rem", borderRadius: "50px" }}
          />
        </span>
      </div>
    </div>
  );
};

export default Loading;
