import React from "react";
import "./styles/coaches.css";
//Assets
import Navbar from "../../components/navbar/navbar";
import IconsCoaches from "../../assets/icons/icons_heroCoaches.svg";
import SectionQuestion from "./sectionQuestion";
import SectionBenefits from "./SectionBenefits";
import SectionListCoaches from "./SectionListCoaches";
import Footer from "../../components/footer/Footer";

const Coaches = () => {
  return (
    <div className="full-container-coaches container-fluid">
      <section className="hero-coaches">
        <Navbar page={"coaches"} />
        <div className="text-hero">
          <h3 className="text-white d-flex text-center fs-4">
            "Conviértete en el corredor que siempre soñaste ser: deja que
            Radaria te guíe paso a paso hacia tu mejor versión."
          </h3>
          <img src={IconsCoaches} alt="iconsCoaches" />
        </div>
      </section>
      <SectionQuestion />
      <div className="motionTribe">
        <h1 className="text-white">MOTION TRIBE</h1>
      </div>
      <SectionBenefits />
      <div className="transparent-section"></div>
      <div className="container-slider-coaches"></div>
      <SectionListCoaches />
      <footer className="container-footer-coaches">
        <Footer />
      </footer>
    </div>
  );
};

export default Coaches;
