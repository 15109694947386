import React from "react";
import "./servicesOffered.css";
import CardService from "./CardService";
import IconCoachService from "../../../assets/icons/IconCoachService.svg";
import ImageFisio from "../../../assets/images/temporalImages/coach.png";
const Coaches = () => {
  const coachesList = [
    {
      name: "Melissa Alvarez4",
      description: "Running profesional con especialidad en nutricion",
      image: ImageFisio,
      score: 4,
      specialist: "RunnerProfesional",
      whatssappUrl: "",
      agendaUrl: "",
      tel: "",
      location: "",
    },
    {
      name: "Melissa Alvarez4",
      description: "Running profesional con especialidad en nutricion",
      image: ImageFisio,
      score: 4,
      specialist: "RunnerProfesional",
      whatssappUrl: "",
      agendaUrl: "",
      tel: "",
      location: "",
    },
    {
      name: "Melissa Alvarez4",
      description: "Running profesional con especialidad en nutricion",
      image: ImageFisio,
      score: 4,
      specialist: "RunnerProfesional",
      whatssappUrl: "",
      agendaUrl: "",
      tel: "",
      location: "",
    },
  ];
  return (
    <section className="subwraper-service">
      <article className="right-wraper">
        <img src={IconCoachService} alt="IconService" />
      </article>
      <article className="left-wraper">
        <div className="container-tile-service">
          <div></div>
          <h2 className="text-white text-center">Entrenadores</h2>
          <div></div>
        </div>
        <div className="left-wraper-cards">
          {coachesList.map((coach, index) => {
            return <CardService dataService={coach} key={index} />;
          })}
        </div>
      </article>
    </section>
  );
};

export default Coaches;
