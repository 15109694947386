import React, { useState } from "react";
import Chart from "react-apexcharts";

const DistanceGraphic = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "runner-stats",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ],
      labels: {
        style: {
          colors: [
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
          ],
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => `${value} km`,
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    title: {
      text: "Distancia recorrida semanal (km)",
      align: "center",
      style: {
        fontSize: "18px",
        color: "#FFFFFF",
        fontWeight: "bold",
      },
    },
    stroke: {
      curve: "smooth", // Hace la línea más curva
      width: 3, // Grosor de la línea
      colors: ["#E7EF7D"], // Color de la línea
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#E7EF7D"],
        fontSize: "14px",
      },
      background: {
        enabled: true,
        foreColor: "#f",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
    fill: {
      type: "gradient",
      colors: ["#E7EF7D"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        gradientToColors: ["rgba(212, 216, 216, 0.32)", "#E7EF7D"],
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Distancia",
      data: [5, 8, 7, 6, 10, 15, 12],
      style: {
        colors: ["#00000"],
      },
    },
  ]);

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="area"
        height={350}
      />
    </div>
  );
};

export default DistanceGraphic;
