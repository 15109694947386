// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/backgrounds/bg_ImageCode.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container-narrow {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 50%;
    display: flex;
    align-items: center;
    padding: 80px;
}

.container-narrow>h2 {
    text-transform: uppercase;
}

.conatiner-narrow-code {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Views/VerifyCodeSMS/verifyCodeSMS.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,yDAA0E;IAC1E,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,iBAAiB;IACjB,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":["@import '../../assets/styles/global.css';\n\n.container-fluid {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.container-narrow {\n    background-image: url(\"../../assets/images/backgrounds/bg_ImageCode.webp\");\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    min-height: 100vh;\n    width: 50%;\n    display: flex;\n    align-items: center;\n    padding: 80px;\n}\n\n.container-narrow>h2 {\n    text-transform: uppercase;\n}\n\n.conatiner-narrow-code {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
