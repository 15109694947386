// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/formas/formGrid.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap-description {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: auto;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
  justify-items: stretch;
}

.side-shape-left {
  position: absolute;
  left: 0px;
  background: linear-gradient(
    -90deg,
    #e7ef7d 0%,
    rgba(231, 239, 125, 0.25) 50%,
    rgba(231, 239, 125, 0.1) 100%
  );
  width: 50%;
  height: 15px;
  border-radius: 0px 10px 10px 0px;
}
.container-icons-desc {
  width: 100%;
  margin-top: 50px;
  padding: 0% 10% 0% 10% !important;
  display: flex;
  justify-content: space-between;
}

.side-shape-right {
  background: linear-gradient(
    90deg,
    #e7ef7d 0%,
    rgba(231, 239, 125, 0.25) 50%,
    rgba(231, 239, 125, 0.1) 100%
  );
  width: 50%;
  height: 15px;
  border-radius: 10px 0px 0px 10px;
  position: absolute;
  right: 0px;
}

.container-body-description {
  margin: auto;
  height: 50vh;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.united {
  margin-bottom: 0px !important;
}

@media (min-width: 1200px) {
  .container-body-description {
    width: 30%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Services/SectionDescription/sectionDescription.css"],"names":[],"mappings":"AAAA;EACE,yDAAmE;EACnE,qBAAqB;EACrB,2BAA2B;EAC3B,4BAA4B;EAC5B,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT;;;;;GAKC;EACD,UAAU;EACV,YAAY;EACZ,gCAAgC;AAClC;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,iCAAiC;EACjC,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE;;;;;GAKC;EACD,UAAU;EACV,YAAY;EACZ,gCAAgC;EAChC,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".wrap-description {\n  background-image: url(\"../../../assets/images/formas/formGrid.png\");\n  background-size: auto;\n  background-position: bottom;\n  background-repeat: no-repeat;\n  width: 100%;\n  height: 100vh;\n  justify-content: space-between;\n  justify-items: stretch;\n}\n\n.side-shape-left {\n  position: absolute;\n  left: 0px;\n  background: linear-gradient(\n    -90deg,\n    #e7ef7d 0%,\n    rgba(231, 239, 125, 0.25) 50%,\n    rgba(231, 239, 125, 0.1) 100%\n  );\n  width: 50%;\n  height: 15px;\n  border-radius: 0px 10px 10px 0px;\n}\n.container-icons-desc {\n  width: 100%;\n  margin-top: 50px;\n  padding: 0% 10% 0% 10% !important;\n  display: flex;\n  justify-content: space-between;\n}\n\n.side-shape-right {\n  background: linear-gradient(\n    90deg,\n    #e7ef7d 0%,\n    rgba(231, 239, 125, 0.25) 50%,\n    rgba(231, 239, 125, 0.1) 100%\n  );\n  width: 50%;\n  height: 15px;\n  border-radius: 10px 0px 0px 10px;\n  position: absolute;\n  right: 0px;\n}\n\n.container-body-description {\n  margin: auto;\n  height: 50vh;\n  width: 70%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.united {\n  margin-bottom: 0px !important;\n}\n\n@media (min-width: 1200px) {\n  .container-body-description {\n    width: 30%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
