import React from "react";
import "./memberships.css";
import { BsFillPatchCheckFill } from "react-icons/bs";

const TableMemberships = ({ memberships }) => {
  let MAX_DESCRIPTION = 0;
  let ID_MEMBERSHIP = 0;

  memberships.map((membership) => {
    if (MAX_DESCRIPTION < membership?.description.length) {
      MAX_DESCRIPTION = membership?.description.length;
      ID_MEMBERSHIP = membership?._id;
      return membership?.description.length;
    }
    return MAX_DESCRIPTION;
  });
  console.log(ID_MEMBERSHIP);

  return (
    <div className="table-membership">
      <table className="table table-dark table-striped ">
        <thead>
          <tr>
            <th scope="col d-flex justify-content-center align-items-center">
              <p className="text-primary">Compara los planes</p>
            </th>
            {memberships.map((membership) => {
              return (
                <th scope="col " key={membership?._id + 1}>
                  <span className="d-block fs-3 text-primary">
                    ${membership?.price}{" "}
                    <span className="fs-6 fw-light">/mes</span>
                  </span>
                  <button className="btn btn-primary">Elegir este plan</button>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {memberships.map((membership) => {
            return membership?.description.map((description, index) => {
              if (membership?._id === ID_MEMBERSHIP) {
                return (
                  <tr key={index}>
                    <th scope="row">{description}</th>
                    {memberships.map((membershipCheck, indexCheck) => {
                      let check = false;
                      for (
                        let i = 0;
                        i < membershipCheck?.description.length;
                        i++
                      ) {
                        if (membershipCheck?.description[i] === description) {
                          check = true;
                        }
                      }
                      return (
                        <td key={indexCheck}>
                          {check ? (
                            <BsFillPatchCheckFill color="#E7EF7D" />
                          ) : (
                            ""
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
              return <tr key={index}></tr>;
            });
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableMemberships;
