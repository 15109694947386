import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../redux/slice/modalSlice";

const ModalAlert = () => {
  const buttonRef = useRef(null); // Referencia al botón que activa el modal
  const { showModal, title, message } = useSelector(
    (state) => state.modalAlertReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal) {
      // Simula el click del botón para abrir el modal
      buttonRef.current.click();
      // Cierra el modal y actualiza el estado
      const handleHidden = () => {
        dispatch(setShowModal(false));
      };
      const modalElement = document.getElementById("staticBackdrop");
      modalElement.addEventListener("hidden.bs.modal", handleHidden);

      return () => {
        modalElement.removeEventListener("hidden.bs.modal", handleHidden);
      };
    }
  }, [showModal, dispatch]);

  return (
    <>
      {/* Botón invisible para activar el modal */}
      <button
        ref={buttonRef}
        type="button"
        className="btn btn-primary d-none" // Clase 'd-none' para ocultar el botón
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>

      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content bg-dark-primary">
            <div className="modal-header">
              <h1
                className="modal-title text-white fs-5"
                id="staticBackdropLabel"
              >
                {title}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-white">{message}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAlert;
