const validateForm = (formValues) => {
  const errors = {};
  // Name validation
  if (!formValues.firstName.trim()) {
    errors.firstName = "The name is required";
  } else if (formValues.firstName.length < 2) {
    errors.firstName = "The name must be at least 2 characters";
  }
  // userName validation
  if (!formValues.nickName) {
    errors.nickName = "The nickName is required";
  } else if (formValues.nickName.length < 2) {
    errors.nickName = "The nickName must be at least 2 characters";
  }
  // Email validation
  if (!formValues.email) {
    errors.email = "The email is required";
  } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
    errors.email = "Invalid email";
  }

  // Password validation
  if (!formValues.password) {
    errors.password = "Password is required";
  } else if (formValues.password.length < 8) {
    errors.password = "Password must be at least 8 characters";
  }

  return errors;
};
export default validateForm;
