// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-card-steps {
  border-radius: 15px;
  border: 1px solid var(--bs-info);
  max-width: 400px;
  min-width: 318px;
  height: 130px;
  margin: auto;
  padding: 20px;
}

.card-steps {
  width: 100%;
  background-color: rgba(212, 216, 216, 0.32);
  height: 90px;
  border-radius: 7.5px;
  font-size: 12px;
}

.rounded-circle {
  display: flex;
  justify-content: center;
}

.rounded-circle > img {
  width: 30px;
  height: 30px;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tooltip {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 1) !important;
  box-shadow: 2px 2px 10px 0px rgba(161, 198, 187, 0.5);
}

.tooltip > div > span {
  font-size: 15px;
}

.tooltip > div > button {
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/StepsToFollow/stepsToFollow.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gCAAgC;EAChC,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,2CAA2C;EAC3C,YAAY;EACZ,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;EAC9B,uCAAuC;EACvC,qDAAqD;AACvD;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".container-card-steps {\n  border-radius: 15px;\n  border: 1px solid var(--bs-info);\n  max-width: 400px;\n  min-width: 318px;\n  height: 130px;\n  margin: auto;\n  padding: 20px;\n}\n\n.card-steps {\n  width: 100%;\n  background-color: rgba(212, 216, 216, 0.32);\n  height: 90px;\n  border-radius: 7.5px;\n  font-size: 12px;\n}\n\n.rounded-circle {\n  display: flex;\n  justify-content: center;\n}\n\n.rounded-circle > img {\n  width: 30px;\n  height: 30px;\n}\n\n.step {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.tooltip {\n  border-radius: 10px !important;\n  background: rgba(0, 0, 0, 1) !important;\n  box-shadow: 2px 2px 10px 0px rgba(161, 198, 187, 0.5);\n}\n\n.tooltip > div > span {\n  font-size: 15px;\n}\n\n.tooltip > div > button {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
