import React from "react";
import "./styles/sectionBenefits.css";
import IconClock from "../../assets/icons/RADARIA-ICONO01.png";
import IconWatch from "../../assets/icons/RADARIA-ICONO37.png";
import IconFire from "../../assets/icons/RADARIA-ICONO05.png";
import IconRunner from "../../assets/icons/RADARIA-ICONO33.png";
import IconStar from "../../assets/icons/RADARIA-ICONO29.png";
import IconPace from "../../assets/icons/RADARIA-ICONO09.png";

const SectionBenefits = () => {
  const benefits = [
    {
      icon: IconClock,
      title: "Entrenamiento perzonalizado",
      subTitle:
        "Diseñados según tus metas (correr 5K, 10K, medio maratón, maratón completa, etc.).",
    },
    {
      icon: IconWatch,
      title: "Plan de nutrición",
      subTitle:
        "Diseñados según tus metas (correr 5K, 10K, medio maratón, maratón completa, etc.).",
    },
    {
      icon: IconFire,
      title: "Radaria Points",
      subTitle:
        "Diseñados según tus metas (correr 5K, 10K, medio maratón, maratón completa, etc.).",
    },
    {
      icon: IconRunner,
      title: "Fisioterapia",
      subTitle:
        "Diseñados según tus metas (correr 5K, 10K, medio maratón, maratón completa, etc.).",
    },
    {
      icon: IconStar,
      title: "Blog",
      subTitle:
        "Diseñados según tus metas (correr 5K, 10K, medio maratón, maratón completa, etc.).",
    },
    {
      icon: IconPace,
      title: "Rutas predeterminadas",
      subTitle:
        "Diseñados según tus metas (correr 5K, 10K, medio maratón, maratón completa, etc.).",
    },
  ];
  return (
    <section className="full-container-benefits pt-5 pb-5">
      <section className="container sectionBenefits">
        {benefits.map((benefit, index) => {
          return (
            <div className="cardBenefits" key={index}>
              <img src={benefit.icon} alt="" />
              <h4 className="text-white text-center">{benefit.title}</h4>
              <p className=" text-center text-danger">{benefit.subTitle}</p>
            </div>
          );
        })}
      </section>
    </section>
  );
};

export default SectionBenefits;
