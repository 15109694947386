import React, { useEffect, useState } from "react";
import MetricsCard from "../../../../components/MetricsCard/MetricsCard";
import {
  calcPersentage,
  calculateTotalRunningTime,
  calculateTotalRunningTimeRoutes,
} from "../../../../helpers/calculateMetrics";

const MetricsOfRoutes = ({ routes }) => {
  const [routesComplete, setRoutesComplete] = useState(0);
  useEffect(() => {
    const calcRoutesComplete = () => {
      const routesComplete = routes.filter((route) => route.complete).length;
      setRoutesComplete(routesComplete);
    };
    calcRoutesComplete();
  }, [routes]);

  return (
    <div className="metrics-section align-items-center">
      <MetricsCard
        title="Rutas Completadas"
        value={`${routesComplete} de ${routes.length}`}
        change={`${calcPersentage(routes.length, routesComplete)}%`}
        changeType={
          calcPersentage(routes.length, routesComplete) <= 60 ? "down" : "up"
        }
      />
      <MetricsCard
        title="Rutas Creadas"
        value={`${routes.length} de 12`}
        change={`${calcPersentage(12, routes.length)}%`}
        changeType={calcPersentage(12, routes.length) <= 60 ? "down" : "up"}
      />
      <MetricsCard
        title="Tiempo recorrido"
        value={`${calculateTotalRunningTime(routes)}min`}
        change={`${calcPersentage(
          calculateTotalRunningTimeRoutes(routes),
          calculateTotalRunningTime(routes)
        )}%`}
        changeType={
          calcPersentage(
            calculateTotalRunningTimeRoutes(routes),
            calculateTotalRunningTime(routes)
          ) <= 60
            ? "down"
            : "up"
        }
      />
    </div>
  );
};

export default MetricsOfRoutes;
