// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap-radar-points {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.container-tile-service > div {
  height: 1px;
}
.wrap-description-radar-points {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
}
.wraper-title-radar-points {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.container-img-map {
  display: flex;
  width: 80%;
}
.container-img-map > img {
  width: 100%;
}
.wrap-cards-radar-points {
  display: grid;
  row-gap: 30px;
  justify-content: center;
}
.wrap-Rinning-club {
  margin-bottom: 250px;
}

@media only screen and (min-width: 768px) {
  .wrap-description-radar-points {
    flex-direction: row;
    justify-content: space-between;
  }
  .wrap-cards-radar-points {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
  .wrap-Rinning-club {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 1024px) {
  .wrap-cards-radar-points {
    grid-template-columns: repeat(4, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Community/styles/radarPoints.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,aAAa;EACb,UAAU;AACZ;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,mBAAmB;IACnB,8BAA8B;EAChC;EACA;IACE,qCAAqC;IACrC,gBAAgB;EAClB;EACA;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":["@import \"../../../assets/styles/global.css\";\n\n.wrap-radar-points {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 50px;\n}\n.container-tile-service > div {\n  height: 1px;\n}\n.wrap-description-radar-points {\n  display: flex;\n  flex-direction: column;\n  width: 80%;\n  justify-content: center;\n  align-items: center;\n}\n.wraper-title-radar-points {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n}\n.container-img-map {\n  display: flex;\n  width: 80%;\n}\n.container-img-map > img {\n  width: 100%;\n}\n.wrap-cards-radar-points {\n  display: grid;\n  row-gap: 30px;\n  justify-content: center;\n}\n.wrap-Rinning-club {\n  margin-bottom: 250px;\n}\n\n@media only screen and (min-width: 768px) {\n  .wrap-description-radar-points {\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  .wrap-cards-radar-points {\n    grid-template-columns: repeat(3, 1fr);\n    column-gap: 30px;\n  }\n  .wrap-Rinning-club {\n    margin-bottom: 100px;\n  }\n}\n\n@media only screen and (min-width: 1024px) {\n  .wrap-cards-radar-points {\n    grid-template-columns: repeat(4, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
