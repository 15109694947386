import './buttonLink.css';
import React from 'react';

const ButtonLink = ({ isAvctive = false, Text, link = '/' }) => {
  const handleClick = () => {
    window.location.href = link;
  };
  return (
    <button
      className={`btn button-link ${
        isAvctive
          ? `btn-primary active animate__animated animate__pulse animate__infinite infinite `
          : `border border-primary text-info inactive`
      }  m-2 ps-4 pe-4 `}
      onClick={handleClick}
    >
      {Text}
    </button>
  );
};

export default ButtonLink;
