// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.continer-not-found {
  background-color: black;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: center;
  align-items: center;
}
.container-message-notfound {
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.wrap-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.wrap-message > img {
  width: 60%;
}
.container-img-not-found > img {
  padding: 30px;
  width: 90%;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .continer-not-found {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1024px) {
}
`, "",{"version":3,"sources":["webpack://./src/Views/NotFound/notFound.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;EACb,UAAU;EACV,aAAa;AACf;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;AACA;AACA","sourcesContent":[".continer-not-found {\n  background-color: black;\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  padding: 30px;\n  justify-content: center;\n  align-items: center;\n}\n.container-message-notfound {\n  justify-content: space-between;\n  align-items: center;\n  gap: 50px;\n}\n\n.wrap-message {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n}\n.wrap-message > img {\n  width: 60%;\n}\n.container-img-not-found > img {\n  padding: 30px;\n  width: 90%;\n  display: flex;\n}\n\n@media only screen and (min-width: 768px) {\n  .continer-not-found {\n    flex-direction: row;\n  }\n}\n@media only screen and (min-width: 1024px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
