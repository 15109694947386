import React from "react";
import "./services.css";
//Components
import Navbar from "../../components/navbar/navbar";
import SectionDescription from "./SectionDescription/SectionDescription";
import Footer from "../../components/footer/Footer";

//Assets
import IconRunner from "../../assets/icons/Running.svg";
import IconWeights from "../../assets/icons/Weights.svg";
import SectionSummary from "./SectionSummary/SectionSummary";
import ServicesOffered from "./ServicesOffered/ServicesOffered";
import IconRadara from "../../assets/images/logotipos/RADARIA-LOGOTIPO04.png";

const Services = () => {
  return (
    <div>
      <div className="conatiner-fluid container-services ">
        <Navbar page={"services"} />
        <section className="container-info pe-4 ps-4">
          <h1 className="text-info text-center">
            Servicios Integrales para Corredores en Radariaa
          </h1>
          <p className="text-info fs-5 text-center mt-3">
            Fomentar un entorno que inspire y motive a los corredores a alcanzar
            sus objetivos y superar sus límites
          </p>
          <div className="d-flex flex-column flex-md-row align-items-center">
            <span className="d-flex m-2 align-items-center container-icon text-info">
              <img
                src={IconRunner}
                width={50}
                alt="icon runner"
                className="m-2"
              />
              +10 Entrenadores
            </span>
            <span className="d-flex m-2 align-items-center text-info">
              <img
                src={IconWeights}
                width={50}
                alt="icon weights"
                className="m-2"
              />
              30+ Ejecercitandose
            </span>
          </div>
        </section>
      </div>
      <section className="container-body-services pb-5">
        <SectionDescription />
        <div className="pharse-services-container">
          <h2 className="text-center text-white">
            Corre más inteligente, más fuerte y más lejos con el apoyo de
            nuestros entrenadores expertos.
          </h2>
        </div>
        <SectionSummary />
        <div className="container-icon-radaria">
          <img src={IconRadara} alt="" />
        </div>
        <ServicesOffered />
      </section>
      <Footer />
    </div>
  );
};

export default Services;
