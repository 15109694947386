import React from "react";
import "./styles/cardCommunity.css";
import { IoHeartCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const CardCommunity = ({ dataService }) => {
  return (
    <div className="card-community">
      <div className="image-community">
        <img src={dataService.image} alt="serviceImage" />
      </div>
      <div className="wrap-card-description-community">
        <div className="container-description">
          <Link className="btn btn-primary" to={dataService.locationUrl}>
            ¡Vamos!
          </Link>
        </div>
        <h3 className="text-secondary d-flex text-start">{dataService.name}</h3>
        <p className="text-white text-center">{dataService.description}</p>
      </div>
      <IoHeartCircleOutline
        className="text-white fs-2 love-icon"
        onClick={() => console.log("M encanta")}
      />
    </div>
  );
};
export default CardCommunity;
