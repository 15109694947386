import React from "react";
import "./styles/community.css";
import Navbar from "../../components/navbar/navbar";
import Runnersgroup from "../../assets/icons/RunnersGroup.svg";
import RadarPoints from "./RadarPoints";
import ComnmunitiesAndEvents from "./ComunitiesAndEvents";
import Footer from "../../components/footer/Footer";
const Community = () => {
  return (
    <section className="wraper-community">
      <div className="hero-community">
        <Navbar page={"community"} />
        <div className="wraper-title-community">
          <h1 className="text-white text-center">
            <strong className="text-secondary">Radaria</strong> una comunidad
            unida
          </h1>
          <img src={Runnersgroup} alt="runners" />
        </div>
      </div>
      <section className="wrap-description-community">
        <div>
          <div className="container-tile-service">
            <div></div>
            <h2 className="text-white text-center">Comunidad de Radaria</h2>
            <div></div>
          </div>
          <p className="text-white text-center">
            Bienvenido a la comunidad que corre contigo. En Radaria, no corres
            solo; aquí encontrarás corredores que comparten tu pasión, te
            motivan y te inspiran a seguir adelante. Porque correr juntos
            siempre es mejor.
          </p>
        </div>
      </section>
      <RadarPoints />
      <section className="wrap-container-phrase-community">
        <h3 className="text-white text-center">
          En los Running Clubs de Radaria, correr en comunidad nunca ha sido tan
          inspirador. Conecta, mejora y disfruta cada kilómetro acompañado.
        </h3>
      </section>
      <ComnmunitiesAndEvents />
      <Footer />
    </section>
  );
};

export default Community;
