import React from 'react';
import './separatorAboutUs.css';
import ScrollAnimation from 'react-animate-on-scroll';
//assets
import IconMapMarker from '../../../assets/icons/map-marked.svg';
import IconCoffe from '../../../assets/icons/Icon-coffee-shadow.svg';
import IconUsersShadow from '../../../assets/icons/IconUsersShadow.svg';
import IconLabelRadaria from '../../../assets/icons/Icon_Label.svg';

const SeparatorAboutUs = () => {
  return (
    <section className="wraper-divider-about text-info">
      <article>
        <ScrollAnimation animateIn="fadeInLeftBig" animateOut="fadeOutLeftBig">
          <span>
            <div>
              <img src={IconLabelRadaria} alt="Icon Radaria" width={'50px'} />
            </div>
            Caracteristicas Radaria
          </span>
          <h2>
            En Radaria creemos que correr es más que un deporte, es un estilo de
            vida.
          </h2>
          <div>
            <p className="text-center">Descarga la App</p>
          </div>
        </ScrollAnimation>
      </article>
      <aside>
        <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight">
          <div>
            <img src={IconMapMarker} alt="" />
            <p>Rutas populares</p>
          </div>
          <div>
            <img src={IconCoffe} alt="" />
            <p>Radar Points</p>
          </div>
          <div>
            <img src={IconUsersShadow} alt="" />
            <p>Comunidad Running</p>
          </div>
        </ScrollAnimation>
      </aside>
    </section>
  );
};

export default SeparatorAboutUs;
