import './progressBar.css'
//Assets

const ProgressBar = () => {
    return (
        <>
            <div>
                <div className="progress-bar bg-primary"></div>
            </div>
        </>
    );
};

export default ProgressBar;