import React, { useEffect, useState } from 'react';
import './styles/testimonialSection.css';
//Components
import CardTestimonial from '../../components/cardTestiomonial/CardTestimonial';
//Assets

const Testiomonials = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);

  const dataTestiomonial = [
    {
      comment:
        "I can't thank Nutritionist enough for their personalized nutrition coaching. It has completely transformed my approach to food and helped me shed those extra pounds. Highly recommended!",
      nameUser: 'Jennifer Anderson',
      urlPhoto: '',
      score: 2,
      usrId: 1,
    },
    {
      comment:
        'Nutritionist has been a game-changer for me. The expert guidance and support I received from their team made my weight loss journey so much easier. Thank you!',
      nameUser: 'Robert Johnson',
      urlPhoto: '',
      score: 4,
      usrId: 2,
    },
    {
      comment:
        "I had struggled with my weight for years until I found Nutritionist. Their personalized approach and tailored nutrition plan made all the difference. I've never felt better!",
      nameUser: 'Emily Davis',
      urlPhoto: '',
      score: 5,
      usrId: 3,
    },
  ];

  useEffect(() => {
    const updateItemsPerPage = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setItemsPerPage(3);
      } else if (width >= 768) {
        setItemsPerPage(2);
      } else {
        setItemsPerPage(1);
      }
    };

    updateItemsPerPage();

    window.addEventListener('resize', updateItemsPerPage);

    // Eliminar el listener cuando se desmonte el componente
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataTestiomonial.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(dataTestiomonial.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  console.log(pageNumbers);

  return (
    <section className="container bg-black  wrap-testimonial">
      <section className="text-info d-flex flex-column align-items-center">
        <h3 className="d-flex justify-content-center">Nuestros clientes</h3>
        <p className="d-flex justify-content-center">
          Nuestros clientes satisfechos comparten sus historias de éxito y
          experiencias en su viaje hacia la salud y bienestar.
        </p>
      </section>
      <section className="subWrap-cards">
        <div className="card-container">
          {currentItems.map((testimonial) => (
            <CardTestimonial
              key={testimonial.usrId}
              dataTestiomonial={testimonial}
            />
          ))}
        </div>
        <div className="pagination">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-primary"
          >
            &lt;
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={`${number === currentPage ? 'active' : ''} btn-circle`}
            ></button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
            className="btn btn-primary"
          >
            &gt;
          </button>
        </div>
      </section>
    </section>
  );
};

export default Testiomonials;
