import React from "react";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const { token } = useParams();

  const handleSubmit = (event) => {
    event.preventDefautl();
    const password = event.target.password.value;
    const confirmPassword = event.target.confirmPassword.value;

    const response = fetch(
      `http://localhost:3800/api/v1/users/forgot-password/${token}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password, confirmPassword }),
      }
    );

    const data = response.json();
    if (data.status === "success") {
      alert("Contraseña actualizada correctamente");
    } else {
      alert(data.message);
    }
  };

  return (
    <form action={handleSubmit}>
      <label>
        Nueva Contraseña:
        <input type="password" name="password" required />
      </label>
      <label>
        Confirmar Contraseña:
        <input type="password" name="confirmPassword" required />
      </label>
      <button type="submit">Restablecer Contraseña</button>
    </form>
  );
};

export default ResetPassword;
