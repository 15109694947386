import React from "react";
import "./sectionDescription.css";
//Assets
import ReadySetGo from "../../../components/ReadySetGo/ReadySetGo";
import IconHeartOnHead from "../../../assets/icons/heartOnHead.svg";
import IconHeartVegetals from "../../../assets/icons/heartVegetals.svg";
import IconAppleOnPeople from "../../../assets/icons/AppleOnPeople.svg";
import IconRunnerFit from "../../../assets/icons/RunnerFit.svg";

const SectionDescription = () => {
  return (
    <section className="wrap-description">
      <div className="side-shape-left"></div>
      <div className="container-icons-desc ">
        <img src={IconHeartOnHead} alt="IconHeartOnHead" width={100} />
        <img src={IconHeartVegetals} alt="IconHeartVegetals" width={100} />
      </div>
      <article className="container-body-description">
        <div className="text-info fs-2">
          <h2 className="d-flex justify-content-center fs-1 text-center">
            Descubre una nueva forma de correr
          </h2>
        </div>
        <p className="text-info text-center">
          Cada paso importa, especialmente cuando es junto a corredores como tú,
          donde las experiencias se unen en una sola, para un camino lleno de
          movimiento.
        </p>
        <ReadySetGo />
      </article>
      <div className="container-icons-desc ">
        <img src={IconAppleOnPeople} alt="IconAppleOnPeople" width={100} />
        <img src={IconRunnerFit} alt="IconRunnerFit" width={100} />
      </div>
      <div className="side-shape-right"></div>
    </section>
  );
};
export default SectionDescription;
