import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./completeRoutes.css";
import {
  hiddeLoading,
  showLoading,
} from "../../../../redux/slice/loadingSlice";
import { getRoutes } from "../../../../services/routes/routesServices";
import Loading from "../../../../components/Loading/Loading";
import MetricsOfRoutes from "./MetricsOfRoutes";
import { LuMapPinPlus } from "react-icons/lu";
import { IoReload } from "react-icons/io5";
import PieGraphic from "../../../../components/Grafhics/PieGraphic/PieGraphic";
import { routesCompleted } from "../../../../helpers/calculateMetrics";
import { LuMapPinCheck } from "react-icons/lu";
import { LuMapPinX } from "react-icons/lu";

const CompleteRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [routesCompletedUser, setRoutesCompletedUser] = useState(0);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoading());
    const getRoutesData = async () => {
      const routes = await getRoutes();
      console.log(routes);

      if (!routes.status) {
        setRoutes([]);
        dispatch(hiddeLoading());
      } else {
        setRoutes(routes.routes?.data);
        setRoutesCompletedUser(routesCompleted(routes.routes?.data));
      }
      if (routes) {
        dispatch(hiddeLoading());
      }
    };
    getRoutesData();
  }, [dispatch, reload]);

  return (
    <section className="container-fluid gap-4 position-relative">
      <Loading />
      <div className="d-flex justify-content-between gap-4">
        <button
          className="btn btn-secondary d-flex align-items-center gap-2"
          style={{ width: "220px" }}
        >
          <LuMapPinPlus />
          Nueva ruta
        </button>
        <input type="date" className="form-control text-white" />
        <IoReload
          className="text-primary fs-1"
          onClick={() => setReload(!reload)}
        />
      </div>
      <MetricsOfRoutes routes={routes} />
      <div className="graphs-container">
        <div className="main-graph">
          {routes.length > 0 && routesCompletedUser >= 0 && (
            <PieGraphic
              labels={[
                `${routes.length} rutas creadas`,
                "12 rutas permitidas",
                `${routesCompletedUser} rutas completadas`,
              ]}
              pieSeries={[routes.length, 12, routesCompletedUser]}
              title={"Resumen de tus Rutas"}
            />
          )}
        </div>
        <div className="side-charts">
          <div className="small-chart">
            <h4 className="text-white">Rutas completadas</h4>
            <table className="table table-dark table-striped-columns ">
              <tr className="table-primary ">
                <td className="table- text-white">Nombre</td>
                <td className="table- text-white">Distancia</td>
              </tr>
              {routes.map((route, index) => {
                if (route.complete)
                  return (
                    <tr className="table-primary" key={index}>
                      <td className="table- text-white">{route.nameRoute}</td>
                      <td className="table- text-white">{route.distance}</td>
                    </tr>
                  );
                return null;
              })}
            </table>
          </div>
          <div className="small-chart">
            <h4 className="text-white">Rutas incompletas</h4>
            <table className="table table-dark table-striped-columns ">
              <tr className="table-primary ">
                <td className="table- text-white">Nombre</td>
                <td className="table- text-white">Distancia</td>
              </tr>
              {routes.map((route, index) => {
                if (!route.complete)
                  return (
                    <tr className="table-primary" key={index}>
                      <td className="table- text-white">{route.nameRoute}</td>
                      <td className="table- text-white">{route.distance}</td>
                    </tr>
                  );
                return null;
              })}
            </table>
          </div>
        </div>
      </div>
      <section>
        <table className="table table-striped table-hover">
          <tr className="table-primary ">
            <td className="celda-table text-white">Nombre</td>
            <td className="celda-table text-white">Descripcion</td>
            <td className="celda-table text-white">Distancia</td>
            <td className="celda-table text-white">Tiempo de recorrido</td>
            <td className="celda-table text-white">Dificultad</td>
            <td className="celda-table text-white">Tipo de terreno</td>
            <td className="celda-table text-white">Incidentes</td>
            <td className="celda-table text-white">Status</td>
          </tr>
          {routes.map((route, index) => {
            return (
              <tr className="table-dark" key={index}>
                <td className="text-wrap text-white celda-table ">
                  {route.nameRoute}
                </td>
                <td className="text-wrap text-white celda-table">
                  {route.description}
                </td>
                <td className="text-wrap text-white celda-table">
                  {route.distance}
                </td>
                <td className="text-wrap text-white celda-table">
                  {route.estimatedTime}
                </td>
                <td className="text-wrap text-white celda-table">
                  {route.difficulty}
                </td>
                <td className="text-wrap text-white celda-table">
                  {route.terrainType}
                </td>
                <td className="text-wrap text-white celda-table">
                  {route.terrainType}
                </td>
                <td className="text-wrap text-white celda-table">
                  {route.terrainType}
                </td>

                <td className="">
                  {route.complete ? (
                    <LuMapPinCheck className="text-primary fs-1 " />
                  ) : (
                    <LuMapPinX className="text-primary fs-1" />
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      </section>
    </section>
  );
};
export default CompleteRoutes;
