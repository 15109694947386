import Home from "../Views/Home/Home";
import CreateAccount from "../Views/CreateAccount/CreateAccount";
import AboutUs from "../Views/AboutUs/About";
import ValidateInfo from "../Views/ValidateInfo/ValidateInfo";
import VerifyCodeSMS from "../Views/VerifyCodeSMS/VerifyCodeSMS";
import Memberships from "../Views/Memberships/Memberships";
import Coaches from "../Views/Coaches/Coaches";
import Services from "../Views/Services/Services";
import ValidateSuccessEmail from "../Views/ValidateSuccessEmail/ValidateSuccesEmail";
import ValidationExpired from "../Views/ValidationExpired/ValidationExpired";
import HistoryActivity from "../Views/Dashboard/pagesUser/HistoryActivity/HistoryActivity";
import ResetPassword from "../Views/ResetPassword/ResetPassword";
import Community from "../Views/Community/Communiy";
import CompleteRoutes from "../Views/Dashboard/pagesUser/CompleteRoutes/CompleteRoutes";
import MapboxMap from "../Views/Dashboard/pagesUser/Routes/NewRoute";
export const routes = [
  {
    name: "Home",
    path: "/",
    Component: Home,
  },
  {
    name: "Auth",
    path: "/auth",
    Component: CreateAccount,
  },

  {
    name: "AboutUs",
    path: "/about-us",
    Component: AboutUs,
  },
  {
    name: "Memberships",
    path: "/memberships",
    Component: Memberships,
  },
  {
    name: "Coaches",
    path: "/coaches",
    Component: Coaches,
  },
  {
    name: "Services",
    path: "/services",
    Component: Services,
  },
  {
    name: "Community",
    path: "/community",
    Component: Community,
  },
  {
    //Pantalla de verificación de email exitosa
    name: "ValidateSuccesWeb",
    path: "/validateSuccess/:name/:origin",
    Component: ValidateSuccessEmail,
  },
  {
    //Pantalla de expiración de token para verificar email
    name: "ValidatonExpired",
    path: "/validationExpired",
    Component: ValidationExpired,
  },
  {
    name: "ResetPassword",
    path: "/reset-password/:token",
    Component: ResetPassword,
  },
];

export const protectedRoutes = [
  {
    name: "Verified",
    path: "/verifyInformation/:name",
    Component: ValidateInfo,
  },
  {
    name: "VerifiedCode",
    path: "/verifyCode/:token",
    Component: VerifyCodeSMS,
  },
];
export const profileRoutes = [
  {
    name: "RutasComplete",
    path: "rutasComplete",
    Component: CompleteRoutes,
  },
  {
    name: "HistoryActivity",
    path: "history",
    Component: HistoryActivity,
  },
  {
    name: "Routes",
    path: "new-route",
    Component: MapboxMap,
  },
];
