import React from 'react';
import './styles/previewAppHome.css';
//Components
import ButtonLink from '../../components/ButtonLink/ButtonLink';
//Assets
import QuoteMark from '../../assets/icons/quotation_marks.svg';
import MapRoute from '../../assets/images/Map-Routes.png';
import AvatarGroup from '../../components/AvatarGroup/AvatarGroup';

const PreviewApp = () => {
  return (
    <section className="wraper-previewApp mt-5">
      <h2 className="text-info">
        <img src={QuoteMark} alt="" />
        Diseña tu propia aventura: crea rutas personalizadas y corre a tu propio
        ritmo.
      </h2>
      <article className="text-info">
        <aside className="wrap-map">
          <img src={MapRoute} alt="" />
          <section>
            <AvatarGroup />
          </section>
        </aside>
        <aside className="d-flex flex-column align-items-center pe-lg-5">
          <p>
            Diseña rutas únicas que se adapten a tu ritmo y estilo. Explora
            nuevos senderos y comparte tus rutas favoritas con otros corredores.
            ¡Inspírate, conéctate y haz de cada carrera una experiencia
            inolvidable!
          </p>
          <ButtonLink Text={'Más...'} isAvctive={true} link="/services" />
        </aside>
      </article>
    </section>
  );
};

export default PreviewApp;
