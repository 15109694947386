import React, { useState } from "react";
import "./sectionQuestions.css";
//Assets
import ImgRunners from "../../../assets/images/Runners-Questions.png";
import IconRadaria from "../../../assets/icons/Icon_Label.svg";
import IconMinus from "../../../assets/icons/IconMinus.svg";
import IconMore from "../../../assets/icons/IconMore.svg";
import ScrollAnimation from "react-animate-on-scroll";

const SectionQuestions = () => {
  const [isActive, setIsActive] = useState(false);
  const [idQuestion, setIdQuestion] = useState(0);
  const questions = [
    {
      id: 1,
      question: "¿Cómo puedo ser parte de Radaria?",
      answer:
        "Puedes registrarte desde nuestra pagina oficial en la seccion de login",
    },
    {
      id: 2,
      question: "¿Cúales son los beneficios que me ofrece?",
      answer:
        "Puedes encontrar descuentos en marcas aliadas de Radaria, asi como sesiones de nutricion o fisioterapia",
    },
    {
      id: 3,
      question: "¿Cómo puedo obtener descuentos exclusivos?",
      answer:
        "Regístrate y descubre los descuentos exclusivos que tenemos para ti, además de las ofertas con nuestros aliados.",
    },
    {
      id: 4,
      question: "¿En qué marcas tengo descuentos?",
      answer: "Podras encontrar beneficios en Nutrinoot",
    },
  ];
  const toggleMenu = (idQuestionSelected) => {
    setIsActive(!isActive);
    setIdQuestion(idQuestionSelected);
  };
  return (
    <>
      <div className="line-separator mt-5"></div>
      <section className="wrap-questions">
        <ScrollAnimation
          animateIn="bounceInLeft"
          animateOut="bounceOutLeft"
          className="wrap-image-questions"
        >
          <img src={ImgRunners} alt="" />
        </ScrollAnimation>

        <ScrollAnimation
          className="wrap-questions-answers text-info"
          animateIn="fadeIn"
          animateOut="fadeOut"
        >
          <div>
            <img src={IconRadaria} alt="" /> <p>Motion Tribe</p>
          </div>
          <h2>Preguntas Frecuentes</h2>
          <section>
            {questions.map((question) => {
              return (
                <div key={question.id} className="card-question">
                  <span>
                    <p className="question">{question.question}</p>
                    {isActive && idQuestion === question.id ? (
                      <img
                        src={IconMinus}
                        alt="Icon minus"
                        onClick={() => toggleMenu(0)}
                      />
                    ) : (
                      <img
                        src={IconMore}
                        alt="IconMore"
                        onClick={() => toggleMenu(question.id)}
                      />
                    )}
                  </span>
                  <p
                    className={`answer ${
                      idQuestion === question.id ? "open" : ""
                    }`}
                  >
                    {question.answer}
                  </p>
                </div>
              );
            })}
          </section>
        </ScrollAnimation>
      </section>
    </>
  );
};

export default SectionQuestions;
