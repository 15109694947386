import React, { useEffect, useState } from "react";
import "../../Views/Dashboard/dashboard.css";
import Sidebar from "../../components/SideBar/Sidebar";
import HeaderSection from "./DashboardMain/HeaderSection";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { status } = useSelector((state) => state.authReducer);

  useEffect(() => {
    const handlePopState = (event) => {
      navigate("/dashboard", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, dispatch, status]);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="dashboard-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="main-content">
        <HeaderSection toggleSidebar={toggleSidebar} />
        <hr className="text-secondary" />
        {/* Contenido */}
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
