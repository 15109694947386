// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  background-color: black;
  color: var(--bs-secondary);
  font-family: "CodecProRegular", sans-serif;
  border-right: 0.1px solid rgba(255, 255, 255, 0.253);
  position: fixed;
  overflow-y: auto;
  scrollbar-width: none;
  width: 250px;
  height: 100%;
  left: -350px;
  top: 0;
  box-sizing: border-box;
  gap: 5px;
  transition: left 0.3s ease;
  z-index: 1000;
}
.sidebar.open {
  left: 0;
}
.close-btn {
  background: none;
  border: 1px solid var(--bs-primary);
  border-radius: 5px;
  padding: 1px;
  color: var(--bs-primary);
  font-size: 30px;
  cursor: pointer;
  margin-bottom: 20px;
}

.sidebar.open {
  left: 0; /* Aparece al abrir */
}

.sidebar-title {
  font-family: "CodecProBold", sans-serif;
}

button.btn-warning {
  background-color: #f9e500;
  color: black;
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  font-weight: bold;
}

button.btn-warning:hover {
  background-color: #ffd700;
}

@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 1024px) {
  .sidebar {
    left: 0;
  }
  .close-btn {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SideBar/styles/sidebar.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,0BAA0B;EAC1B,0CAA0C;EAC1C,oDAAoD;EACpD,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,MAAM;EACN,sBAAsB;EACtB,QAAQ;EACR,0BAA0B;EAC1B,aAAa;AACf;AACA;EACE,OAAO;AACT;AACA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,kBAAkB;EAClB,YAAY;EACZ,wBAAwB;EACxB,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,OAAO,EAAE,qBAAqB;AAChC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;AACA;AACA;EACE;IACE,OAAO;EACT;EACA;IACE,aAAa;EACf;AACF","sourcesContent":["@import \"../../../assets/styles/global.css\";\n\n.sidebar {\n  background-color: black;\n  color: var(--bs-secondary);\n  font-family: \"CodecProRegular\", sans-serif;\n  border-right: 0.1px solid rgba(255, 255, 255, 0.253);\n  position: fixed;\n  overflow-y: auto;\n  scrollbar-width: none;\n  width: 250px;\n  height: 100%;\n  left: -350px;\n  top: 0;\n  box-sizing: border-box;\n  gap: 5px;\n  transition: left 0.3s ease;\n  z-index: 1000;\n}\n.sidebar.open {\n  left: 0;\n}\n.close-btn {\n  background: none;\n  border: 1px solid var(--bs-primary);\n  border-radius: 5px;\n  padding: 1px;\n  color: var(--bs-primary);\n  font-size: 30px;\n  cursor: pointer;\n  margin-bottom: 20px;\n}\n\n.sidebar.open {\n  left: 0; /* Aparece al abrir */\n}\n\n.sidebar-title {\n  font-family: \"CodecProBold\", sans-serif;\n}\n\nbutton.btn-warning {\n  background-color: #f9e500;\n  color: black;\n  width: 100%;\n  padding: 12px;\n  border-radius: 12px;\n  font-weight: bold;\n}\n\nbutton.btn-warning:hover {\n  background-color: #ffd700;\n}\n\n@media only screen and (min-width: 768px) {\n}\n@media only screen and (min-width: 1024px) {\n  .sidebar {\n    left: 0;\n  }\n  .close-btn {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
