import React, { useState } from "react";
//Components
import Loading from "../../../components/Loading/Loading";
import { FaEye } from "react-icons/fa";
import validateForm from "./validateLogin";
import { loginAuth } from "../../../services/users/userService";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { login, setUser } from "../../../redux/slice/authSlice";
import { useDispatch } from "react-redux";
import { setModalContent, setShowModal } from "../../../redux/slice/modalSlice";

const FormSigIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialFormValues = {
    email: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formValues);
    console.log(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const userData = { ...formValues };
      setLoading(!loading);
      const response = await loginAuth(userData);
      if (!response.status) {
        setLoading(false);
        toast.error(response.message, { duration: 10000 });
      } else {
        const message = `Bienvenido a Radaria ${response.data?.user?.email}`;
        setLoading(false);
        console.log(response.data);
        dispatch(login({ token: response.data?.token, isAuthenticated: true }));
        dispatch(setUser(response.data?.user));
        toast.success(`${message}`, { duration: 10000 });
        setFormValues(initialFormValues);
        navigate("/dashboard/history");
        if (response.data?.user.status === "inactive") {
          dispatch(setShowModal());
          dispatch(
            setModalContent({
              title: "Favor de verificar su correo",
              message: "Le enviamos un correo con su enlace de verificación.",
              action: "",
            })
          );
        }
        setErrors({});
      }
    }
    setErrors({});
  };
  return (
    <>
      <Loading visible={loading} />
      <form action="" onSubmit={handleSubmit} className="d-flex flex-column">
        <div className="mb-3 " style={{ width: "85%" }}>
          <label htmlFor="inputEmailLogin" className="form-label">
            Correo electrónico{" "}
          </label>
          <input
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleChange}
            className={`form-control ${
              errors.email && "is-invalid input-error"
            } text-light`}
            id="inputEmai"
            placeholder="correo@ejemplo.com"
          />
          {errors.email && (
            <div id="validationServer05Feedback" className="invalid-feedback">
              {errors.email}
            </div>
          )}
        </div>
        <div className="mb-3 mt-3 " style={{ width: "85%" }}>
          <label htmlFor="inputPasswordLogin" className="form-label">
            Contraseña
          </label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formValues.password}
              onChange={handleChange}
              className={`form-control ${
                errors.password && "is-invalid input-error"
              } text-light`}
              id="inputPasswordLogin"
              placeholder="Ingrese su contraseña"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
            />
            <button
              className="btn btn-outline-primary"
              type="button"
              id="button-addon2"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FaEye />
            </button>
          </div>
          {errors.password && (
            <div id="validationServer05Feedback" className="invalid-feedback">
              {errors.password}
            </div>
          )}
        </div>
        <div className="col-auto mt-4">
          <button type="submit" className="btn btn-primary ">
            Ingresar
          </button>
        </div>
        <div className="mt-3">
          <Link to="/reset-password">¿Olvidaste tu contraseña?</Link>
        </div>
      </form>
    </>
  );
};
export default FormSigIn;
