import React from "react";
import "./metricsCard.css";
import { FaEye } from "react-icons/fa6";

const MetricsCard = ({ title, value, change, changeType }) => {
  return (
    <div className="metrics-card">
      <section className="d-flex justify-content-between container-fluid">
        <h3 className="metrics-card-title ">{title}</h3>
        <FaEye />
      </section>
      <section className="d-flex align-items-center gap-2">
        <p className="value">{value}</p>
        <p className={`change ${changeType}`}>
          {changeType === "up" ? "▲" : "▼"} {change}
        </p>
      </section>
    </div>
  );
};

export default MetricsCard;
