import React from "react";
import "../DashboardMain/styles/headerSection.css";
import { useSelector } from "react-redux";
import { RiMenuFold2Line } from "react-icons/ri";
const HeaderSection = ({ toggleSidebar }) => {
  const { email } = useSelector((state) => state.authReducer);

  return (
    <div className="header-section-component">
      <div className="header-text">
        <RiMenuFold2Line className="menu-btn " onClick={toggleSidebar} />
        <section>
          <h1 className="fs-3"> Bienvenido a Radaria, {email} </h1>
          <p>Podra seguir paso a paso su progreso.</p>
        </section>
      </div>
      <div className="header-actions">
        <button className="btn btn-transparentColor text-white">
          Export data ↓
        </button>
        <button className="btn btn-primary">Create report</button>
      </div>
    </div>
  );
};

export default HeaderSection;
