import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
  title: "",
  message: "",
  action: "",
};

const modaAlertlSlice = createSlice({
  name: "modalAlert",
  initialState,
  reducers: {
    setShowModal: (state) => {
      state.showModal = !state.showModal;
    },
    setModalContent: (state, action) => {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.action = action.payload.action;
    },
    clearModalValues: (state, action) => {
      state.showModal = false;
      state.title = "";
      state.message = "";
      state.action = "";
    },
  },
});

export const { clearModalValues, setModalContent, setShowModal } =
  modaAlertlSlice.actions;
export default modaAlertlSlice.reducer;
