import "./navbar.css";
import { useSelector } from "react-redux";
//Assets
import uconUser from "../../assets/icons/user-circle.svg";
import iconRadaria from "../../assets/images/logotipos/RADARIA-VARIACIÓN04.png";
import { Link } from "react-router-dom";
const Navbar = ({ page }) => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);

  return (
    <>
      <div className="containe-fluid d-flex justify-content-between ps-4 pe-4 pt-4">
        <Link className="navbar-brand " to="/">
          <img
            className="icon_radaria"
            src={iconRadaria}
            alt="icon the radaria"
          />
        </Link>
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid container-nav">
            <button
              className="navbar-toggler rounded-pill"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse container-mobile"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item ms-3 me-3 d-flex align-items-center">
                  <Link
                    className={`nav-link  ${
                      page === "about-us" ? "active" : ""
                    } d-flex align-items-center`}
                    aria-current="page"
                    to="/about-us"
                  >
                    Nosotros
                  </Link>
                </li>
                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                  <Link
                    className={`nav-link  ${
                      page === "membership" ? "active" : ""
                    } d-flex align-items-center`}
                    to="/memberships"
                  >
                    Membresías
                  </Link>
                </li>
                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                  <Link
                    className={`nav-link  ${
                      page === "coaches" ? "active" : ""
                    } d-flex align-items-center`}
                    to="/coaches"
                  >
                    Entrenadores
                  </Link>
                </li>
                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                  <Link
                    className={`nav-link ${
                      page === "services" ? "active" : ""
                    }  d-flex align-items-center`}
                    to="/services"
                  >
                    Servicios
                  </Link>
                </li>
                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                  <Link
                    className={`nav-link  ${
                      page === "community" ? "active" : ""
                    } d-flex align-items-center`}
                    to="/community"
                  >
                    Comunidad
                  </Link>
                </li>
                <li className="btn_signIng nav-item ms-3 me-3 d-flex align-items-center">
                  <Link
                    className="nav-link  d-flex align-items-center"
                    to={isAuthenticated ? "/dashboard" : "/auth"}
                  >
                    <img src={uconUser} alt="Icon User" className="me-2" />
                    {!isAuthenticated && "Iniciar Sesión"}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
