import React, { useState } from 'react';
import './about.css';
//Components
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/Footer';
import SectionAboutUs from './SectionAboutUs/SectionAboutUs';
import SeparatorAboutUs from './SeparatorAboutus/SeparatorAboutUs';
import SectionValors from './SectionValors/SectionValors';
//Assets
import iconArrow from '../../assets/images/angle-right.png';
import RadariaIsotipo from '../../assets/icons/RADARIA-ISOTIPO01.png';
import SectionQuestions from './SectionQuestions/SectionQuestions';
const AboutUs = () => {
  //animation button
  const [animate, setAnimate] = useState(false);
  const handleClick = () => {
    setAnimate(true);
    setTimeout(() => {
      window.location.href = '/auth';
    }, 1500);
  };
  return (
    <main className="wrap-about">
      <section className="hero-about">
        <Navbar page={'about-us'} />
        <article className="text-info">
          <div className="wrap-title animate__animated animate__bounce">
            <h1 className="hidden">Radaria</h1>
            <img src={RadariaIsotipo} alt="Isotipo Radaria" />
          </div>
          <p className="text-center animate__animated animate__bounce">
            Explora nuevos caminios y mantente motivado mientras logras tus
            objetivos
          </p>
          <div className="container_btn animate__animated animate__backInLeft ">
            <button
              className={`btn_start   ${animate ? 'animate' : ''}`}
              onClick={handleClick}
            >
              Start here
            </button>
            <img src={iconArrow} alt="" />
          </div>
        </article>
      </section>
      <SectionAboutUs />
      <SeparatorAboutUs />
      <SectionValors />
      <SectionQuestions />
      <Footer />
    </main>
  );
};

export default AboutUs;
