import React, { useState } from "react";
import Map, { Marker, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MapboxMap = () => {
  const [viewport, setViewport] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 10,
  });
  const [location, setLocation] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
  });
  const [error, setError] = useState(null);

  const requestLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setViewport({
            ...viewport,
            latitude,
            longitude,
          });
          setError(null);
        },
        (err) => {
          setError("No se pudo obtener la ubicación. " + err.message);
        },
        {
          enableHighAccuracy: true, // Usar GPS para mayor precisión
          timeout: 10000, // Tiempo máximo para obtener la ubicación
          maximumAge: 0, // No usar datos en caché
        }
      );
    } else {
      setError("La geolocalización no está soportada por este navegador.");
    }
  };

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <button onClick={requestLocation}>Obtener mi ubicación</button>
      <Map
        {...viewport}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} // Usa tu token
        onMove={(evt) => setViewport(evt.viewState)}
        mapStyle="mapbox://styles/mapbox/streets-v11" // Estilo del mapa
      >
        {/* Controles de navegación */}
        <NavigationControl position="top-right" />

        {/* Ejemplo de un marcador */}
        <Marker latitude={location.latitude} longitude={location.longitude}>
          <div className="fs-3" style={{ color: "red" }}>
            📍
          </div>
        </Marker>
      </Map>
    </div>
  );
};

export default MapboxMap;
