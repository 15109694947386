import React from "react";
import "./styles/sectionQuestion.css";
import ImgRunners from "../../assets/images/RunnersCardPart.png";
import IconPairRunners from "../../assets/icons/PairRunners.svg";
import IconListCheck from "../../assets/icons/ListCheck.png";
import IconListCheckPlus from "../../assets/icons/listeCheckCross.svg";
import IconListCheckHealt from "../../assets/icons/ListCheckApple.svg";

const SectionQuestion = () => {
  return (
    <section className="container-questionCoaches pt-5 pb-5">
      <article className="imageQuestion">
        <div className="d-flex justify-content-center align-items-center">
          <img src={ImgRunners} alt="ImageRunner" />
        </div>
      </article>
      <article className="questionsCoaches gap-3">
        <div className="d-flex flex-column align-items-center">
          <h2 className="text-center  ">
            ¿Cómo funciona el coaching de{" "}
            <strong className="text-secondary ">Radaria</strong>?
          </h2>
          <p className="text-center">
            Queremos conocerte más, nos ayudarás a responder una serie de
            preguntas, esto nos permitirá conocer más sobre tu historia
            deportiva.
          </p>

          <ul className="d-flex flex-column  list-group justify-content-center ">
            <li className="d-flex gap-3 align-items-center ">
              <img src={IconPairRunners} alt="Runners" />
              <p>Elige el coach que mejor se adapta a tus necesidades.</p>
            </li>
            <li className="d-flex gap-3 align-items-center ">
              <img src={IconListCheck} alt="IconListCheck" />
              <p>Antecedentes, historia deportiva y análisis de hábitos.</p>
            </li>
            <li className="d-flex gap-3 align-items-center ">
              <img src={IconListCheckPlus} alt="IconListCheckPlus" />
              <p>Evaluaciones antropométricas y de condición física general.</p>
            </li>
            <li className="d-flex gap-3 align-items-center ">
              <img src={IconListCheckHealt} alt="IconListCheckHealt" />
              <p>Resultados y brindamos coaching nutricional y deportivo.</p>
            </li>
          </ul>
        </div>
      </article>
    </section>
  );
};

export default SectionQuestion;
