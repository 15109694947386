// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  list-style: none;
}
.btn-membership {
  box-shadow: 0px 0px 7px 0.5px rgba(255, 255, 255, 1);
}
.card-membership {
  background: linear-gradient(
    180deg,
    rgba(212, 216, 216, 0.12) 0%,
    rgba(212, 216, 216, 0.04) 50%,
    rgba(212, 216, 216, 0.07) 100%
  ) !important;
  height: auto !important;
  min-height: auto !important;
  border-radius: 32px !important;
  border: 1px solid rgba(212, 216, 216, 0.32) !important;
  -webkit-backdrop-filter: blur(42px) !important;
          backdrop-filter: blur(42px) !important;
}
.card-membership:hover {
  border: 1px solid var(--bs-primary) !important;
}
.card-body {
  height: auto !important;
  min-height: auto !important;
}
`, "",{"version":3,"sources":["webpack://./src/Views/CardMembership/cardMembership.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;AAClB;AACA;EACE,oDAAoD;AACtD;AACA;EACE;;;;;cAKY;EACZ,uBAAuB;EACvB,2BAA2B;EAC3B,8BAA8B;EAC9B,sDAAsD;EACtD,8CAAsC;UAAtC,sCAAsC;AACxC;AACA;EACE,8CAA8C;AAChD;AACA;EACE,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":["@import \"../../assets/styles/global.css\";\n\nli {\n  list-style: none;\n}\n.btn-membership {\n  box-shadow: 0px 0px 7px 0.5px rgba(255, 255, 255, 1);\n}\n.card-membership {\n  background: linear-gradient(\n    180deg,\n    rgba(212, 216, 216, 0.12) 0%,\n    rgba(212, 216, 216, 0.04) 50%,\n    rgba(212, 216, 216, 0.07) 100%\n  ) !important;\n  height: auto !important;\n  min-height: auto !important;\n  border-radius: 32px !important;\n  border: 1px solid rgba(212, 216, 216, 0.32) !important;\n  backdrop-filter: blur(42px) !important;\n}\n.card-membership:hover {\n  border: 1px solid var(--bs-primary) !important;\n}\n.card-body {\n  height: auto !important;\n  min-height: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
