import React from "react";
import ButtonLink from "../../components/ButtonLink/ButtonLink";
import "./styles/servicesHome.css";
//Assets
import MapRadarPints from "../../assets/images/MapRadarPoints.png";
import RunningCommunityPark from "../../assets/images/RunningCommunityPark.png";
import LineAsset from "../../assets/icons/LineCommunity.svg";
const ServicesHome = () => {
  return (
    <div className="bg-black">
      <section className="bg-black  pt-3 container-radarPoints">
        <article>
          <h3 className="text-info">Radar Points</h3>
          <p className="text-info text-center">
            Explora los puntos de encuentro running más visitados, y disfruta de
            la experiencia de unirte a la mejor red de corredores de México
          </p>
          <ButtonLink Text="Más..." isAvctive={true} link="/community" />
        </article>
        <div className="pt-5">
          <img src={MapRadarPints} alt="Map of radar points" />
        </div>
      </section>
      <section className="mt-5 container-Community">
        <article className="container-infoCommunity">
          <div className="container-imageRunning">
            <img src={RunningCommunityPark} alt="" />
          </div>
          <h3 className="text-primary mt-2">Comunidades</h3>
          <section className="text-info d-flex">
            <div className="card-community-text">
              <span className="text-primary">
                <b className="me-1">30+</b>Usuarios
              </span>
              <p className="text-center">Tips para runners.</p>
            </div>
            <img src={LineAsset} alt="" className="line" />
            <div className="card-community-text">
              <span className="text-primary">
                <b className="me-1">50+</b> Grupos
              </span>
              <p className="text-center">Disponibles para ti.</p>
            </div>
            <img src={LineAsset} alt="" className="line" />

            <div className="card-community-text">
              <span className="text-primary">
                <b>80+</b>
              </span>
              <p className="text-center"> Nuevas Opciones.</p>
            </div>
          </section>
        </article>
        <article className="d-flex justify-content-center">
          <div className="bg-image-community">
            <p className="text-info p-2 text-center">
              "La comunidad te espera: desde consejos para correr hasta los
              mejores lugares para entrenar, todo en un solo lugar"
            </p>
            <ButtonLink Text={"Más..."} isAvctive={true} link="/community" />
          </div>
        </article>
      </section>
    </div>
  );
};

export default ServicesHome;
