import React, { useRef } from 'react';
import '../Home/styles/carouselHome-styles.css';
import FEED00 from '../../assets/images/RADARIA FEED.png';
import FEED01 from '../../assets/images/RADARIA FEED-01.png';
import FEED02 from '../../assets/images/RADARIA FEED-02.png';
import FEED03 from '../../assets/images/RADARIA FEED-03.png';
import FEED04 from '../../assets/images/RADARIA FEED-04.png';
import FEED05 from '../../assets/images/RADARIA FEED-05.png';
import FEED06 from '../../assets/images/RADARIA FEED-06.png';
import FEED07 from '../../assets/images/RADARIA FEED-07.png';

const CarouselHome = () => {
  const sliderRef = useRef(null);
  let isDown = false;
  let startX;
  let scrollLeft;

  const handleMouseDowm = (e) => {
    isDown = true;
    sliderRef.current.classList.add('active');
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
    sliderRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    isDown = false;
    sliderRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Ajusta la velocidad del arrastre
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      className="slider-container bg-black"
      ref={sliderRef}
      onMouseDown={handleMouseDowm}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <div className="slider-track d-flex">
        <div className="slider-item">
          <img src={FEED00} alt="Imagen 1" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED01} alt="Imagen 2" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED02} alt="Imagen 3" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED03} alt="Imagen 4" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED04} alt="Imagen 5" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED05} alt="Imagen 6" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED06} alt="Iamgen 7" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED07} alt="Imagen 8" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED00} alt="Imagen 1" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED01} alt="Imagen 2" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED02} alt="Imagen 3" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED03} alt="Imagen 4" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED04} alt="Imagen 5" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED05} alt="Imagen 6" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED06} alt="Iamgen 7" className="img-fluid" />
        </div>
        <div className="slider-item">
          <img src={FEED07} alt="Imagen 8" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default CarouselHome;
