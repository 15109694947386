import React from "react";
import "./styles/graphicsSection.css";
import DistanceGraphic from "./DistanceGraphic";
import TimeGraphics from "./TimeGraphic";
import RunnerStats from "./RunnerStats";

const GraphicsSection = () => {
  const data = [
    {
      id: "norway",
      color: "hsl(220, 70%, 50%)",
      data: [
        { x: "plane", y: 150 },
        { x: "helicopter", y: 400 },
        { x: "boat", y: 200 },
        { x: "train", y: 600 },
        { x: "subway", y: 800 },
        { x: "bus", y: 300 },
        { x: "car", y: 700 },
        { x: "moto", y: 600 },
        { x: "bicycle", y: 500 },
        { x: "horse", y: 100 },
        { x: "skateboard", y: 700 },
        { x: "others", y: 900 },
      ],
    },
    {
      id: "germany",
      color: "hsl(30, 70%, 50%)",
      data: [
        { x: "plane", y: 200 },
        { x: "helicopter", y: 300 },
        { x: "boat", y: 400 },
        { x: "train", y: 500 },
        { x: "subway", y: 600 },
        { x: "bus", y: 700 },
        { x: "car", y: 300 },
        { x: "moto", y: 400 },
        { x: "bicycle", y: 500 },
        { x: "horse", y: 700 },
        { x: "skateboard", y: 800 },
        { x: "others", y: 300 },
      ],
    },
  ];
  return (
    <div className="graphs-container">
      <div className="main-graph">
        <DistanceGraphic />
      </div>
      <div className="side-charts">
        <div className="small-chart">
          <TimeGraphics />
        </div>
        <div className="small-chart">
          <RunnerStats />
        </div>
      </div>
    </div>
  );
};

export default GraphicsSection;
