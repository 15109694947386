// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-code {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.conatiner-narrow-input-code {
    display: flex;
    min-width: 300px;
    margin: 10px;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-code {
    width: 30px !important;
    font-size: 30px !important;
    margin: 10px;
    border-radius: 10px !important;
    text-align: center;
    padding: 0px !important;
    border: none !important;
}

.input-code::placeholder {
    color: black !important;
}

.container-code>button {
    border: none;
}

.container-code>button:hover {
    background-color: var(--bs-info);
    box-shadow: 1px 1px 5px 1px var(--bs-primary);
    border: none;
}

.error-code {
    box-shadow: 0px 0px 10px 1px rgba(255, 0, 0, 1);
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/Views/VerifyCodeSMS/FormCode/formCode.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB;;;AAGA;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;IACtB,0BAA0B;IAC1B,YAAY;IACZ,8BAA8B;IAC9B,kBAAkB;IAClB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gCAAgC;IAChC,6CAA6C;IAC7C,YAAY;AAChB;;AAEA;IACI,+CAA+C;IAC/C,UAAU;AACd","sourcesContent":[".container-code {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.conatiner-narrow-input-code {\n    display: flex;\n    min-width: 300px;\n    margin: 10px;\n}\n\n\ninput[type=number]::-webkit-inner-spin-button,\ninput[type=number]::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\ninput[type=number] {\n    -moz-appearance: textfield;\n}\n\n.input-code {\n    width: 30px !important;\n    font-size: 30px !important;\n    margin: 10px;\n    border-radius: 10px !important;\n    text-align: center;\n    padding: 0px !important;\n    border: none !important;\n}\n\n.input-code::placeholder {\n    color: black !important;\n}\n\n.container-code>button {\n    border: none;\n}\n\n.container-code>button:hover {\n    background-color: var(--bs-info);\n    box-shadow: 1px 1px 5px 1px var(--bs-primary);\n    border: none;\n}\n\n.error-code {\n    box-shadow: 0px 0px 10px 1px rgba(255, 0, 0, 1);\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
