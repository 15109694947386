import React from "react";
import "./sectionSummary.css";
//Assets
import FormRadaria from "../../../assets/images/FormRadaria.webp";
import IconNutrition from "../../../assets/icons/IconNutrition.svg";
import IconCoaches from "../../../assets/icons/IconCoaches.svg";
import IconFisioterapia from "../../../assets/icons/IconFisioterapia.svg";
import IconPsicologia from "../../../assets/icons/IconPsicologia.svg";
import IconRutinas from "../../../assets/icons/Frame 1000005793.svg";
import IconEventos from "../../../assets/icons/Frame 1000005794.svg";

const servicesCard = [
  {
    icon: IconNutrition,
    title: "Nutrición",
    content: "Planes personalizados para mejorar tu energía y rendimiento.",
  },
  {
    icon: IconCoaches,
    title: "Entrenadores",
    content: "Rutinas y entrenamientos personalizados para tu progreso.",
  },
  {
    icon: IconFisioterapia,
    title: "Fisioterapeutas",
    content: "Prevén y trata lesiones con fisioterapia especializada.",
  },
  {
    icon: IconPsicologia,
    title: "Psicología",
    content: "Fortalece tu mente con apoyo en psicología deportiva.",
  },
  {
    icon: IconEventos,
    title: "Eventos",
    content: "Compite y conéctate en eventos exclusivos para corredores.",
  },
  {
    icon: IconRutinas,
    title: "Rutas Personalizadas",
    content:
      "Rutas personalizados de acuerdo a tus necesidades para alcanzar tus objetivos.",
  },
];

const verifyPar = (index) => {
  if (index % 2 === 0) {
    return true;
  }
  return false;
};

const SectionSummary = () => {
  return (
    <section className="d-flex flex-column align-items-center">
      <div className="container-form">
        <img src={FormRadaria} alt="Frorm Radaria" className="form-radaria " />
      </div>
      <article className="cards-container-services mt-5 ">
        {servicesCard.map((service, index) => {
          return (
            <div
              className={`card-container-services text-info ${
                index + 1 !== 6 && "border-bottom-sm"
              } ${verifyPar(index + 1) && "border-left"} ${
                index + 1 !== 5 && index + 1 !== 6 && "border-bottom-md"
              } ${index + 1 !== 3 && index + 1 !== 6 && "border-right"} ${
                index + 1 !== 4 &&
                index + 1 !== 5 &&
                index + 1 !== 6 &&
                "border-bottom-lg"
              }`}
              key={index}
            >
              <img src={service.icon} alt="Icon biceps" width={60} />
              <h2 className="fs-4 fw-bold">{service.title}</h2>
              <p className="fs-6 text-center">{service.content}</p>
            </div>
          );
        })}
      </article>
    </section>
  );
};
export default SectionSummary;
