import React from "react";
import "./cardService.css";
import { IoHeartCircleOutline } from "react-icons/io5";
import { FiMapPin } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";

const CardService = ({ dataService }) => {
  return (
    <div className="card-service">
      <div className="image-service">
        <div className="container-description">
          <div className="contact-wraper">
            <p className="text-white text-center">{dataService.description}</p>
            <div>
              <Link to={dataService.whatssappUrl}>
                <FaWhatsapp className="fs-3" />
              </Link>
              <Link to={dataService.agendaUrl}>
                <FaRegCalendarAlt className="fs-3" />
              </Link>
              <Link to={dataService.tel}>
                <FaPhoneAlt className="fs-3" />
              </Link>
            </div>
          </div>
        </div>
        <img src={dataService.image} alt="serviceImage" />
      </div>
      <div className="container-score mt-3">
        {Array.from({ length: dataService.score }, (_, index) => (
          <FaStar key={index} className="text-warning" />
        ))}
      </div>
      <div>
        <h3 className="text-white d-flex text-start">{dataService.name}</h3>
        <p className="text-white d-flex text-start">{dataService.specialist}</p>
      </div>
      <div className="container-location-like">
        <IoHeartCircleOutline
          className="text-white fs-2"
          onClick={() => console.log("M encanta")}
        />
        <Link to={dataService.location}>
          <FiMapPin className="fs-3 text-white" />
        </Link>
      </div>
    </div>
  );
};
export default CardService;
