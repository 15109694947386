const validateForm = (formValues) => {
  const errors = {};
  // Email validation
  if (!formValues.email) {
    errors.email = "The email is required";
  } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
    errors.email = "Invalid email";
  }

  // Password validation
  if (!formValues.password) {
    errors.password = "Password is required";
  } else if (formValues.password.length < 8) {
    errors.password = "Password must be at least 8 characters";
  }

  return errors;
};
export default validateForm;
