import React, { useState, useEffect } from "react";
import "./formCode.css";
import { validateSMS } from "../../../services/users/userService";
import toast, { Toaster } from "react-hot-toast";
import Loading from "../../../components/Loading/Loading";

const FormCode = ({ token }) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const initialValues = {
        codeDigit1: "",
        codeDigit2: "",
        codeDigit3: "",
        codeDigit4: "",
        codeDigit5: "",
        codeDigit6: "",
    };
    const [codeDigits, setCodeDigits] = useState(initialValues);


    // Move focus to the next input after the codeDigits state has been updated
    useEffect(() => {
        const inputs = document.querySelectorAll('.input-code');
        const lastInput = Array.from(inputs).find(input => input.value === '' && input !== document.activeElement);
        if (lastInput) {
            lastInput.focus();
        }
    }, [codeDigits]);

    const validateCode = () => {
        for (let digit in codeDigits) {
            if (codeDigits[digit] === "" || codeDigits[digit].length > 1) {
                return true; // Exit early if there's an error
            }
        }
        return false;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (/^\d?$/.test(value)) { // Allow only single digit
            setCodeDigits(prev => ({
                ...prev,
                [name]: value,
            }));
        }
        setError(false);
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').slice(0, 6); // Get first 6 digits
        const inputs = document.querySelectorAll('.input-code');
        pastedData.split('').forEach((char, index) => {
            if (index < inputs.length) {
                inputs[index].value = char;
                setCodeDigits(prev => ({
                    ...prev,
                    [`codeDigit${index + 1}`]: char,
                }));
            }
        });
        // Move focus to the next input
        inputs[Math.min(pastedData.length, inputs.length - 1)].focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateCode();
        setLoading(true);
        if (isValid) {
            setLoading(false);
            setError(isValid);
        } else {
            const codeUser = `${codeDigits.codeDigit1}${codeDigits.codeDigit2}${codeDigits.codeDigit3}${codeDigits.codeDigit4}${codeDigits.codeDigit5}${codeDigits.codeDigit6}`;
            console.log(codeUser);

            const response = await validateSMS({ code: codeUser }, token);
            console.log(response);

            if (!response.status) {
                setLoading(false);
                setError(true);
                const message = response.message;
                toast.error(`${message}`, { duration: 10000 })
            } else {
                setCodeDigits(initialValues);
                setLoading(false);
                setError(false);
                toast.success("Bienvenido a Radaria!", { duration: 10000 })

            }

        }
    };

    return (
        <>
            <Loading visible={loading} />
            <Toaster
                position="top-left"
                reverseOrder={false}
                containerStyle={{
                    top: 100,
                    left: 20,
                    bottom: 20,
                    right: 50,
                }}
                toastOptions={{
                    success: {
                        style: {
                            background: 'rgba(0, 0, 0, 50%)',
                            color: 'white'

                        },
                    },
                    error: {
                        style: {
                            background: 'rgba(0, 0, 0, 50%)',
                            color: 'white'
                        },
                    },
                }}

            />
            <div className="container-code">
                <p className="text-primary fs-4">Enter your verification code</p>
                <form onSubmit={handleSubmit} className="d-flex flex-column">
                    <div className="conatiner-narrow-input-code">
                        {[1, 2, 3, 4, 5, 6].map((digit) => (
                            <input

                                key={digit}
                                type="text"
                                name={`codeDigit${digit}`}
                                value={codeDigits[`codeDigit${digit}`]}
                                onChange={handleChange}
                                onPaste={handlePaste}
                                className={`form-control input-code bg-info ${error ? "error-code" : ""}`}
                                maxLength={1}
                                style={{ width: '40px', textAlign: 'center', margin: '0 5px' }}
                            />
                        ))}
                    </div>
                    <button type="submit" className="btn btn-primary">Verify</button>
                </form>
            </div>
        </>
    );
};

export default FormCode;
