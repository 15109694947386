// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  display: flex;
  background-color: black;
  width: 100%;
  min-height: 100vh;
}

.main-content {
  padding: 35px;
  background-color: black;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 1024px) {
  .main-content {
    margin-left: 250px;
    width: calc(100% - 250px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Dashboard/dashboard.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;;AAEA;AACA;AACA;EACE;IACE,kBAAkB;IAClB,yBAAyB;EAC3B;AACF","sourcesContent":["@import \"../../assets/styles/global.css\";\n\n.dashboard-container {\n  display: flex;\n  background-color: black;\n  width: 100%;\n  min-height: 100vh;\n}\n\n.main-content {\n  padding: 35px;\n  background-color: black;\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n  width: 100%;\n}\n\n@media only screen and (min-width: 768px) {\n}\n@media only screen and (min-width: 1024px) {\n  .main-content {\n    margin-left: 250px;\n    width: calc(100% - 250px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
