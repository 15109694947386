import React, { useState } from "react";
import "../Memberships/memberships.css";
//Components
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import CardMembership from "../CardMembership/CardMembership";
import TableMemberships from "./TableMemberships";
//Asseets
import IsotipoRadaria from "../../assets/images/isotipos/RADARIA-ISOTIPO01-PNG.webp";
import IconBrain from "../../assets/icons/IconBrain.svg";
import IconMedal from "../../assets/icons/IconMedal.svg";
import IconWhistle from "../../assets/icons/IconWhistle.svg";
import formPoligonal from "../../assets/images/formas/formPoligonal_Radial.png";
import IconRunnersPair from "../../assets/icons/runners_pair.svg";

const Memberships = () => {
  const [selected, setSelected] = useState(2);

  const memberships = [
    {
      _id: 1,
      planName: "Classic",
      price: 999,
      currency: "MXN",
      description: ["Acceso ilimitado", "Beneficios Exclusivos"],
    },
    {
      _id: 2,
      planName: "Pro",
      price: 200,
      currency: "MXN",
      description: [
        "Acceso ilimitado",
        "Beneficios Exclusivos",
        "Acceso ilimitado2",
        "Beneficios Exclusivos2",
      ],
    },
    {
      _id: 3,
      planName: "Premium",
      price: 150,
      currency: "MXN",
      description: [
        "Acceso ilimitado",
        "Beneficios Exclusivos",
        "Acceso ilimitado2",
        "Beneficios Exclusivos2",
        "Acceso ilimitado3",
        "Beneficios Exclusivos4",
      ],
    },
  ];
  return (
    <div className="memberships-container container-fluid">
      <div className="header-image">
        <Navbar page={"membership"} />
        <div className="overlay-content">
          <h1 className="text-white">
            <img
              src={IsotipoRadaria}
              alt="Isotipo Radaria"
              className="isoti-title"
            />
            adaria
          </h1>
          <p className="text-white fs-2">Membresías</p>
          <span className="text-white fs-3">
            "Únete a nuestra comunidad y logra tus objetivos acompañado de los
            mejores"
          </span>
          <div className="d-flex mt-4 justify-content-center">
            <img src={IconBrain} alt="IconBrain" />
            <img src={IconMedal} alt="IconMedal" />
            <img src={IconWhistle} alt="IconWhistle" />
          </div>
        </div>
      </div>
      <section className="container mt-5 d-flex justify-content-center">
        <img src={formPoligonal} alt="formPoligonal" id="formPoligonal" />
        <div className="phase-container">
          <h4 className="text-white fs-3">
            Convierte cada kilómetro en una experiencia única con nuestra
            membresía.
          </h4>
          <p className="text-white fs-6 mt-3">
            Selecciona el mejor plan, asegurando una combinación perfecta.
            ¿Necesitas más o menos? ¡Personaliza tu suscripción para un ajuste
            perfecto!
          </p>
        </div>
      </section>
      <div className="container mt-4 mb-5">
        <div className="row align-items-start justify-content-center">
          {memberships.map((membership) => {
            return (
              <CardMembership
                _id={membership._id}
                planName={membership.planName}
                description={membership.description}
                currency={membership.currency}
                price={membership.price}
                key={membership._id}
                selected={membership._id === selected}
                setSelected={setSelected}
              />
            );
          })}
        </div>
      </div>
      <div className="container-slider">
        <h2 className="text-white">
          "Da el primer paso hacia una vida más sana y activa. ¡Hazte miembro
          hoy!"
        </h2>
      </div>
      <section className="table-container">
        <div className="d-flex container justify-content-center mb-4">
          <span className="text-white fs-3">
            Compara todas las funciones y comienza tu vida saludable.
          </span>
          <img
            src={IconRunnersPair}
            alt="runners-pair"
            width={70}
            className="ms-3"
          />
        </div>
        <TableMemberships memberships={memberships} />
      </section>
      <Footer />
    </div>
  );
};

export default Memberships;
