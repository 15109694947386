import React from "react";
import "./servicesOffered.css";
import CardService from "./CardService";
import FisioterapiaIcon from "../../../assets/icons/FisioterapiaIcon.svg";
import ImageFisio from "../../../assets/images/temporalImages/coach.png";
const FisioService = () => {
  const fisioList = [
    {
      name: "Melissa Alvarez4",
      description: "Running profesional con especialidad en nutricion",
      image: ImageFisio,
      score: 4,
      specialist: "RunnerProfesional",
      whatssappUrl: "",
      agendaUrl: "",
      tel: "",
      location: "",
    },
    {
      name: "Melissa Alvarez4",
      description: "Running profesional con especialidad en nutricion",
      image: ImageFisio,
      score: 4,
      specialist: "RunnerProfesional",
      whatssappUrl: "",
      agendaUrl: "",
      tel: "",
      location: "",
    },
    {
      name: "Melissa Alvarez4",
      description: "Running profesional con especialidad en nutricion",
      image: ImageFisio,
      score: 4,
      specialist: "RunnerProfesional",
      whatssappUrl: "",
      agendaUrl: "",
      tel: "",
      location: "",
    },
  ];
  return (
    <section className="subwraper-service">
      <article className="left-wraper">
        <div className="container-tile-service">
          <div></div>
          <h2 className="text-white text-center">Fisioterapia</h2>
          <div></div>
        </div>
        <div className="left-wraper-cards">
          {fisioList.map((fisio, index) => {
            return <CardService dataService={fisio} key={index} />;
          })}
        </div>
      </article>
      <article className="right-wraper">
        <img src={FisioterapiaIcon} alt="IconService" />
      </article>
    </section>
  );
};

export default FisioService;
