// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import '../../../assets/styles/global.css'; */

.header-section-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-top: 0;
  width: 100%;
  color: #d4d8d8;
  border-radius: 8px;
  gap: 20px;
}
.menu-btn {
  background: none;
  border: 1px solid var(--bs-primary);
  border-radius: 5px;
  padding: 1px;
  font-size: 30px;
  cursor: pointer;
  color: var(--bs-primary);
}
.header-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.header-actions {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
}

.header-text h1 {
  font-family: "CodecProBold", sans-serif;
  color: #d4d8d8;
}

.header-text p {
  font-family: "CodecProRegular", sans-serif;
  color: #d4d8d8;
}

.header-actions .btn {
  margin-left: 10px;
}

@media only screen and (min-width: 768px) {
  .header-section-component {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .header-text {
    width: 60%;
  }
  .header-actions {
    width: 40%;
  }
}
@media only screen and (min-width: 1024px) {
  .menu-btn {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Dashboard/DashboardMain/styles/headerSection.css"],"names":[],"mappings":"AAAA,iDAAiD;;AAEjD;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,uCAAuC;EACvC,cAAc;AAChB;;AAEA;EACE,0CAA0C;EAC1C,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;EACrB;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* @import '../../../assets/styles/global.css'; */\n\n.header-section-component {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: start;\n  margin-top: 0;\n  width: 100%;\n  color: #d4d8d8;\n  border-radius: 8px;\n  gap: 20px;\n}\n.menu-btn {\n  background: none;\n  border: 1px solid var(--bs-primary);\n  border-radius: 5px;\n  padding: 1px;\n  font-size: 30px;\n  cursor: pointer;\n  color: var(--bs-primary);\n}\n.header-text {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 100%;\n}\n\n.header-actions {\n  display: flex;\n  width: 100%;\n  gap: 10px;\n  justify-content: center;\n}\n\n.header-text h1 {\n  font-family: \"CodecProBold\", sans-serif;\n  color: #d4d8d8;\n}\n\n.header-text p {\n  font-family: \"CodecProRegular\", sans-serif;\n  color: #d4d8d8;\n}\n\n.header-actions .btn {\n  margin-left: 10px;\n}\n\n@media only screen and (min-width: 768px) {\n  .header-section-component {\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .header-text {\n    width: 60%;\n  }\n  .header-actions {\n    width: 40%;\n  }\n}\n@media only screen and (min-width: 1024px) {\n  .menu-btn {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
