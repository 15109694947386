// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/backgrounds/bg-validateInfo.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_verified {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.subwraper {
  background: rgba(0, 0, 0, 70%);
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Views/ValidationExpired/validationExpired.css"],"names":[],"mappings":"AAEA;EACE,yDAA6E;EAC7E,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX","sourcesContent":["@import \"../../assets/styles/global.css\";\n\n.container_verified {\n  background-image: url(\"../../assets/images/backgrounds/bg-validateInfo.webp\");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.subwraper {\n  background: rgba(0, 0, 0, 70%);\n  max-width: 300px;\n  margin: auto;\n  padding: 20px;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
