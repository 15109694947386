// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.menu-item-select {
  color: var(--bs-primary);
}
.list-menu {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.item {
  cursor: pointer;
  list-style: none;
  padding: 5px;
  border-radius: 5px;
}
.item > a {
  text-decoration: none;
  color: white;
}
.item:hover {
  border-left: 2px solid var(--bs-primary);
  background-color: var(--bs-transparentColor);
}

.item-select {
  border-left: 2px solid var(--bs-primary);
  background-color: var(--bs-transparentColor);
}
`, "",{"version":3,"sources":["webpack://./src/components/SideBar/styles/menu.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,wCAAwC;EACxC,4CAA4C;AAC9C;;AAEA;EACE,wCAAwC;EACxC,4CAA4C;AAC9C","sourcesContent":["@import \"../../../assets/styles/global.css\";\n\n.menu-item {\n  width: 100%;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n}\n.menu-item-select {\n  color: var(--bs-primary);\n}\n.list-menu {\n  padding-left: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n.item {\n  cursor: pointer;\n  list-style: none;\n  padding: 5px;\n  border-radius: 5px;\n}\n.item > a {\n  text-decoration: none;\n  color: white;\n}\n.item:hover {\n  border-left: 2px solid var(--bs-primary);\n  background-color: var(--bs-transparentColor);\n}\n\n.item-select {\n  border-left: 2px solid var(--bs-primary);\n  background-color: var(--bs-transparentColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
