const handleErrorAPI = (error) => {
  if (error.response) {
    //Error of API
    return {
      status: false,
      message:
        error.response.data.message ||
        "En este momento no se puede procesar su solicitud, intentelo más tarde.",
      statusCode: error.response.status,
    };
  } else if (error.request) {
    //Error of
    return {
      status: false,
      message: "Ocurrio un error desconocido, intentelo mas tarde.",
      errorCode: error.code,
    };
  } else {
    //Error for Network
    return {
      status: false,
      message: "Ocurrio un error mientras se procesaba su solicitud.",
      errorCode: error.code,
    };
  }
};

export default handleErrorAPI;
