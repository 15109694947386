// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.element-go {
    border: solid 1px var(--bs-info);
    border-radius: 50px;
    display: flex;
    padding: 5px 15px 5px 5px;
    align-items: center;
    max-width: 300px;
}

.element-go>img {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/ReadySetGo/readySetGo.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;AACrB","sourcesContent":[".element-go {\n    border: solid 1px var(--bs-info);\n    border-radius: 50px;\n    display: flex;\n    padding: 5px 15px 5px 5px;\n    align-items: center;\n    max-width: 300px;\n}\n\n.element-go>img {\n    border-radius: 50%;\n    vertical-align: middle;\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
