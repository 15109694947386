// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metrics-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .metrics-section {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1024px) {
  .metrics-section {
    flex-direction: row;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Dashboard/pagesUser/HistoryActivity/styles/metricsSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;AACA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".metrics-section {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  border-radius: 8px;\n  width: 100%;\n}\n\n@media only screen and (min-width: 768px) {\n  .metrics-section {\n    flex-direction: row;\n  }\n}\n@media only screen and (min-width: 1024px) {\n  .metrics-section {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
