import React, { useState } from "react";
import "./sectionValors.css";
import ScrollAnimation from "react-animate-on-scroll";
//Assets
import ImageRunning from "../../../assets/images/RunnerValors.png";
import IconRunner from "../../../assets/icons/RADARIA-ICONORunner.svg";
import IconAngleUp from "../../../assets/icons/angle-up-icon.svg";
import IconAngleDown from "../../../assets/icons/angle-down-icon.svg";
const SectionValors = () => {
  const [activeCard, setActiveCard] = useState(3);
  const toggleCard = (cardIndex) => {
    setActiveCard(activeCard === cardIndex ? 3 : cardIndex);
  };
  return (
    <section className="wraper-section-valors">
      <ScrollAnimation
        className="wraper-valors "
        animateIn="bounceInLeft"
        animateOut="bounceOutLeft"
      >
        <div
          className={`${
            activeCard === 1 ? "CardActive" : ""
          } text-info card-valors Card1`}
        >
          <section>
            <div className="container-runner-linea">
              <div id="lineaRunner"></div>
              <img src={IconRunner} alt="" />
            </div>
            <div>
              <span className={`${activeCard === 1 ? "text-primary" : ""} `}>
                Valores
              </span>
              {activeCard === 1 ? (
                <button className="button-angle" onClick={() => toggleCard(1)}>
                  <img src={IconAngleDown} alt="" />
                </button>
              ) : (
                <button className="button-angle" onClick={() => toggleCard(1)}>
                  <img src={IconAngleUp} alt="" />
                </button>
              )}
            </div>
          </section>
          <div className="wrap-info-valors">
            <div
              className={`blur-action ${
                activeCard === 1 ? "card-active" : "card-inactive"
              }`}
            ></div>
            <ul>
              <li>
                <b>Comunidad:</b>Correr juntos nos fortalece.
              </li>
              <li>
                <b>Superación:</b>Siempre buscamos mejorar.
              </li>
              <li>
                <b>Conexión:</b>Relaciones que te impulsan.
              </li>
              <li>
                <b>Pasión:</b>El running es nuestro motor.
              </li>
              <li>
                <b>Innovación:</b>Herramientas que potencian el rendimiento.
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`${
            activeCard === 2 ? "CardActive" : ""
          } text-info card-valors Card2`}
        >
          <section>
            <div className="container-runner-linea">
              <div id="lineaRunner"></div>
              <img src={IconRunner} alt="" />
            </div>
            <div>
              <span className={`${activeCard === 2 ? "text-primary" : ""} `}>
                Visión
              </span>
              {activeCard === 2 ? (
                <button className="button-angle" onClick={() => toggleCard(2)}>
                  <img src={IconAngleDown} alt="" />
                </button>
              ) : (
                <button className="button-angle" onClick={() => toggleCard(2)}>
                  <img src={IconAngleUp} alt="" />
                </button>
              )}
            </div>
          </section>
          <div className="wrap-info-valors">
            <div
              className={`blur-action ${
                activeCard === 2 ? "card-active" : "card-inactive"
              }`}
            ></div>
            <p className="pe-3 ps-3">
              Ser la plataforma líder que motiva a corredores a superar sus
              límites, conectándose con una comunidad global apasionada por el
              running.
            </p>
          </div>
        </div>
        <div
          className={`${
            activeCard === 3 ? "CardActive" : ""
          } text-info card-valors Card3`}
        >
          <section>
            <div className="container-runner-linea">
              <div id="lineaRunner"></div>
              <img src={IconRunner} alt="" />
            </div>
            <div>
              <span className={`${activeCard === 3 ? "text-primary" : ""} `}>
                Misión
              </span>
              {activeCard === 3 ? (
                <button className="button-angle" onClick={() => toggleCard(3)}>
                  <img src={IconAngleDown} alt="" />
                </button>
              ) : (
                <button className="button-angle" onClick={() => toggleCard(3)}>
                  <img src={IconAngleUp} alt="" />
                </button>
              )}
            </div>
          </section>
          <div className="wrap-info-valors">
            <div
              className={`blur-action ${
                activeCard === 3 ? "card-active" : "card-inactive"
              }`}
            ></div>
            <p className="pe-3 ps-3">
              Conectar a corredores de todo el mundo, brindando herramientas
              para mejorar y compartir experiencias, creando una comunidad donde
              cada paso sea un avance hacia la superación.
            </p>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation
        className="wraper-image"
        animateIn="bounceInRight"
        animateOut="bounceOutRight"
      >
        <img src={ImageRunning} alt="" />
      </ScrollAnimation>
    </section>
  );
};

export default SectionValors;
